@import "../../variables";

.not-found-container {
  font-family: $heading;
  color: $grey;
  font-size: 22px;
  position: relative;
  width: 100%;
  height: 500px;
  z-index: 1;

  .not-found-content {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  &.no-text {
    width: 400px;
    top: 50%;
  }

  &.onboarding {
    margin-left: -100px;
  }

  .robot {
    width: 340px;
    float: left;
    margin-right: 40px;
    &.error-img {
      width: 300px;
    }
  }

  .not-found-text {
    position: relative;
    top: 70px;
    font-family: $paragraph;
    float: left;
    height: 75px;
    .not-found-link {
      color: white;
    }
    .header-msg {
      font-weight: 600;
      margin-bottom: 25px;
    }
    a {
      text-decoration: none;
      color: $white;
    }
  }

  &.panel {
    all: unset;
    width: 100%;
    height: 100%;
    .robot{
      position: relative;
    }
    .not-found-content{
      position: relative;
      margin-left: 70px;
      margin-top: 30px;
    }
    .not-found-text{
    }

    &.vulnerabilities{
      margin-top: -80px;
    }
    &.service{
      margin-top: -50px;
    }
    &.modal{
      .not-found-content{
        width: 800px;
      }
    }
  }
}
