@import "../../../variables";
.adding-account-modal {
  width: 500px;
  margin-left: 20%;
  .response-modal{
     font-family: $paragraph;
     .response-data-row{
       padding-left: 50px;
     }
    .response-modal-title{
      border-bottom: 1px #616B6B solid;
      margin: 0 -20px 20px -75px;
      padding: 0 0 10px 70px;
      font-size: 16px;
    }
    .response-modal-description{
      padding: 0 0 0 30px;
      font-size: 14px;
      color: #a6bfbf;
    }
    .response-modal-note{
      padding: 0 0 0 30px;
      font-size: 14px;
      color: #a6bfbf;
    }
    .response-modal-note-italics{
      font-size: 14px;
      color: #a6bfbf;
    }
    .response-modal-row-title{
      padding: 0 0 0 30px;
      font-size: 16px;
      color: #a6bfbf;
      width: 22%;
    }
    .border-btn-container{
      float: right;
      margin-right: -20px;
    }
    .bold-note{
      display:inline;
      font-weight: bolder;
      color: white;
    }
  }

  .modal-data {
    height: unset;
    padding: 12px 20px 20px 12px;

    a {
      text-decoration: none;
      color: $yellow;
      cursor: pointer;

      &:hover {
        color: $white
      }
    }

    .loading-text {
      display: flex;
      align-items: center;
      padding: 3px;

      svg {
        margin-left: 10px;
      }
    }

    .indent {
      margin: -10px 0 0 20px;
    }
    .auto-response{
      color: $yellow;
    }
  }
}
