@import "../../variables";

.service-panels-container {
  position: relative;
  .service-panel {
    position: relative;
    border-bottom: 1px solid #3a5054;
    clear: both;
    height: 175px;
    padding: 40px 60px 40px 60px;
    &.Endpoints {
      border-top: 1px solid #3a5054;
      margin-top: 50px;
    }
    &.Add{
      height: 250px;
      margin-bottom: 30px;
    }
    .service-panel-header {
      .total {
        float: left;
        margin-right: 15px;
        font-size: 50px;
        color: $grey;
        line-height: 45px;
        .data-unit {
          font-size: 25px;
          margin-left: 2px;
        }
      }
      .title {
        float: left;
        color: $lightgrey;
        font-family: $heading;
        letter-spacing: 1px;
      }
    }
    .service-panel-stats {
      position: absolute;
      right: 60px;
      color: $grey;

      .contracted {
        float: left;
        margin-right: 50px;
        width: 110px;
        .amount {
          color: $lightgrey;
          margin-left: 7px;
        }
      }
      .percent-used {
        float: left;
        width: 70px;
      }
    }
    .service-panel-services {
      position: absolute;
      top: 90px;
      left: 170px;
      width: 500px;
      .service {
        float: left;
        margin-right: 50px;
        min-width: fit-content;
        width: 70px;
        &.disabled{
          opacity: 0.5;
        }
        .service-icon {
          float: left;
          width: 50px;
          &.gsuite {
            width: 27px;
          }
          &.aws {
            margin-top: 7px;
            width: 40px;
          }
          &.o365 {
            margin-left: -12px;
            width: 50px;
          }
          &.iOS {
            width: 32px;
            margin-left: -5px;
            margin-right: -5px;
          }
          &.Android {
            width: 50px;
            margin-left: -5px;
            margin-right: -5px;
          }
          &.Mac,
          &.Windows,
          &.Linux {
            margin: -12px -12px -12px -12px;
          }
          &.Windows {
            margin-top: -9px;
          }
          &.OKTA {
            width: 150px;
            margin-left: -50px;
            margin-right: -61px;
            margin-top: -30px;
          }
          &.log {
            width: 70px;
            margin-left: -13px;
            margin-right: -15px;
            margin-top: -25px;
          }
          &.evm{
            width: 60px;
            margin-left: -10px;
            margin-top: -15px;
            margin-right: -10px;
            margin-bottom: -2px;
          }
          &.device_control{
            width: 60px;
            margin-top: -15px;
            margin-left: -10px;
            margin-right: -5px;
          }
        }
        .total {
          float: left;
          color: $grey;
          margin-top: 5px;
          margin-left: 10px;
          font-size: 15px;
        }
        .icon-label{
          clear: both;
          width: 100px;
          color: $grey;
          font-size: 14px;
          line-height: 18px;
          &.evm{
            margin-left: 4px;
          }
        }
        &.Android,
        &.iOS {
          .total {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
