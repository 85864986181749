@import "../../../variables";

.crowdstrike-policy-modal-container {
  .relative-loader {
    position: relative;
    left: 650px;
    top: 90px;
  }
  .modal-container {
    width: 1350px;
    &.single-column-cs{
      width: 1000px;
      left: 21%;
    }
    &.cs-no-results{
      width: 500px;
      left: 32%;
    }
    &.no-workstation{
      width: 800px;
      left: 25%;
    }
  }
  .group-id-error{
    position: relative;
    top: 110px;
    left: 25px;
  }
  &.fetch-error {
    .modal-container {
      width: 500px;
    }
  }
  .modal-data {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    min-height: 300px;
    height: fit-content;
  }
  .cs-options-container {
    height: fit-content;
    margin-top: 40px;
    display: table;
    .modal-options-os {
      display: table-cell;
      position: relative;
      padding-right: 75px;
      border-right: 1px solid $darkgrey;
      height: fit-content;
      padding-bottom: 50px;
      padding-left: 20px;
    }
    .modal-options-os:last-child {
      border-right: none;
      padding-right: 0px;
    }
    .modal-options-os:first-child {
      padding-left: 0px;
    }
  }
  .loader-btn-container {
    position: absolute;
    right: 50px;
    bottom: 30px;
    .relative-loader {
      position: relative;
      margin-right: 25px;
      top: 10px;
      left: 0px;
    }
    &.success{
      color: $green;
    }
  }
  .submit-error {
    color: $red;
    margin-top: 3px;
    position: absolute;
    padding-left: 50px;
    z-index: 10;
    right: 165px;
    bottom: 35px;
    font-size: 15px;
    &.text-wrapped {
      bottom: 5px;
      font-size: 14px;
    }
    .err-icon {
      position: relative;
      color: $red;
      margin-right: 3px;
      top: 2.5px;
    }
  }
  .error-img {
    position: relative;
    left: 470px;
    width: 400px;
  }
  .cs-option-list {
    z-index: 99;
    min-width: fit-content;
    display: flex;
    .server-options {
      float: left;
      flex: 50%;
      margin-right: 50px;
    }
    .workstation-options {
      float: left;
      flex: 50%;
    }
    ul {
      list-style-type: none;
      padding-left: 10px;
      padding-bottom: 10px;
      padding-inline-start: 0px;
      li {
        padding-bottom: 0px;
        margin-bottom: 5px;
      }
    }
    .cs-label {
      font-size: 14px;
      margin-top: 15px;
      margin-bottom: 5px;
      color: $grey;
      user-select: none;
      font-family: $paragraph;
    }
  }

  .cs-policy-os {
    color: $white;
    margin-top: -6px;
    font-family: $roboto-bold;
    font-weight: 600;
  }

  .radio-container {
    width: 200px;
    position: relative;
    top: 5px;
    cursor: pointer;
    .radio {
      float: left;
      margin-top: 4px;
      width: 13px;
      margin-right: 5px;
    }
    .policy-name {
      user-select: none;
      padding-left: 20px;
      font-family: $paragraph;
      color: $lightgrey;
    }
  }
}
