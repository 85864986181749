@import "../../../variables";

.vulnerability-scan-onboarding {
  .tab-container {
    display: inline-block;
    width: 100%;
  }
  .vulnerabilitiy-description {
    margin-bottom: 40px;
  }
  .vuln-scan-tabs {
    display: flex;
    margin-bottom: 50px;
    white-space: nowrap;
    float: left;
    width: fit-content;
  }
  .tab-border {
    margin-top: 43.5px;
    margin-left: 222px;
    width: calc(100% - 222px);
    border-bottom: 1px solid $darkgrey;
  }
  .link {
    color: $white;
    cursor: pointer;
    &.instructions {
      font-size: 14px;
      margin-left: 20px;
    }
    text-decoration: none;
  }
  .scan_name {
    padding-left: 20px;
  }

  .scan-time-tooltip {
    position: relative;
    left: 86px;
    bottom: 24px;
    cursor: pointer;
    z-index: 100;
  }

  .scan-day-tooltip {
    position: relative;
    left: 136px;
    bottom: 25px;
    cursor: pointer;
    z-index: 100;
    width: 90px;
  }

  .instructions-tooltip {
    position: absolute;
    right: 0px;
    top: 8px;
    .tooltip-icon:hover {
      opacity: 0.9;
    }
  }
  .add-ip-container {
    position: relative;
    margin-top: 40px;
    margin-bottom: 50px;
    height: 400px;
    &.edit {
      margin-top: 0px;
      margin-bottom: 30px;
      height: 200px;
      width: 700px;
      .ip-input {
        height: 150px;
        width: 450px;
      }
      .add-btn-container {
        width: fit-content;
        left: 345px;
        top: 150px;
        margin-top: 50px;
        .loader-btn {
          position: relative;
          margin-bottom: 2px;
        }
      }
    }
    .add-btn-container {
      position: relative;
      left: 445px;
      margin-top: 15px;
    }
  }
  .dropdown-section {
    position: relative;
    .input-label {
      color: $grey;
    }
    .hostname-dropdown-container {
      position: relative;
      left: 226px;
      bottom: 25px;

      .dropdown-container {
        position: absolute;
        overflow-y: auto;
      }
    }
  }
  .reveal-add {
    width: fit-content;
    color: $grey;
    cursor: pointer;
    margin-top: -50px;
    margin-bottom: 50px;
    user-select: none;
    &:hover {
      color: $white;
    }
  }
  .vm-link {
    margin-left: 20px;
    color: $grey;
    cursor: pointer;
    &:hover {
      color: $yellow;
    }
  }
  .vuln-scan-tooltip {
    width: 175px;
    z-index: 100;
  }
  .dropdown-container {
    display: block;
    &.open {
      max-height: 255px;
      overflow-y: scroll;
      z-index: 100;
    }
  }
  .dropdown-title {
    color: $white;
    font-weight: 600;
    user-select: none;
  }
  .dropdown-label {
    color: $grey;
    position: absolute;
  }
  .list-item {
    position: relative;
    z-index: 99;
    background-color: $darkcyan;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 7px;
    &:hover {
      color: $white;
    }
  }
}
