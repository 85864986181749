@import "../../../variables";
.dense-table {
  &.virtual-machine {
    .dense-table-header {
    }

    td {
      &:first-child {
        padding-left: 20px;
      }
    }
  }
  .confirm-cell {
    height: 5px;
    width: 200px;
    color: $grey;
    .confirm {
      padding-right: 0px;
      :hover {
        color: $white;
      }
    }
    .cancel {
      padding-left: 5px;
      :hover {
        color: $white;
      }
    }
    .color {
      color: $grey;
    }
  }
  .table-column {
    &.hostname {
      width: 10%;
    }

    &.ip_address {
      width: 20%;
    }

    &.public_ip_address {
      width: 20%;
    }

    &.vm_roles {
      width: 20%;
    }

    &.hypervisor_version {
      width: 15%;
    }
    &.buttons {
      width: 8%;
    }
  }
  .icon-container {
    position: relative;
    padding-left: 30px;
    .delete-btn{
      width: 12px;
    }
  }

}
