@import '../../variables.scss';

.tooltip-container {
  display: inline-block;
  position: relative;

  &.action{
    width: 200px;
    position: absolute;
  }

  .tooltip-icon {
    padding-left: 6px;
    margin-bottom: 3px;
    width: 21px;
    cursor: pointer;
  }

  &.action-value {
    width: 200px;
    height: 0;
    position: absolute;
    .bottom {
      top: 25px !important;
    }
  }

  &.log-source-config {
    width: 100%;
  }

  &.contacts-onboarding {
    top: 3px;
    width: 100%;
  }

  .tooltip {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px;
    color: $white;
    background: #365b62;
    font-size: 14px;
    line-height: 1.2;
    font-family: $paragraph;
    z-index: 99;
    width: 200px;
    border: 1px solid #5f7f84;
    
    &.top {
      top: -80px;
    }

    &.right {
      left: calc(100% + 10px);
      top: 50%;
      transform: translateX(0) translateY(-50%);
    }

    &.bottom {
      top: 30px;
    }

    &.left {
      left: auto;
      right: calc(100% + 30px);
      top: 50%;
      transform: translateX(0) translateY(-50%);
    }

    &.center {
      left: calc(85% + 12px);
      top: 50%;
      transform: translateX(0) translateY(-50%);
    }

    &.title-tooltip {
      white-space: nowrap;
      width: fit-content;
      &.true {
        display: none;
      }
    }

    &.large-tooltip {
      white-space: pre-wrap;
    }
  }
}
