@import "../../../variables";

.vulnerability-scan-onboarding {
  .table-column {
    &.scan_name {
      width: 25%;
    }
    &.scan_ips{
      width: 40%;
    }
    &.icon-btns{
      width: 175px;
    }
  }
}
