@import "../../../variables";

.account-form-container {
  table {
    border-spacing: 0;
  }

  tr {
    height: 40px;
  }

  td {
    width: fit-content;
    white-space: nowrap;
      &.response-td-data{
        vertical-align: top;
        margin-top: 10px;
        padding-top: 4px;
      }
      &.edit-response{
        vertical-align: top;
        padding-top: 9px;
        .response-modal-note{
          font-size: 14px;
          color: #a6bfbf;
          font-family: $paragraph;
          .bold-note{
            display:inline;
            font-weight: bolder;
            color: $white;
          }
        }
        .response-modal-note-italics{
          font-size: 14px;
          color: #a6bfbf;
          font-family: $paragraph;
          .bold-note{
            display:inline;
            font-weight: bolder;
            color: $white;
          }
        }
      }
  }
  .fileName-upload{
    display: flex;
    .fileName{
      width: 200px;
      padding-right: 20px;
    }
  }

  min-width: 650px;
  width: 60%;

  .input-container {
    height: 40px;
    margin-bottom: 10px;
    min-width: 650px;
    width: fit-content;
  }

  .input-label {
    line-height: 40px;
    margin-right: 12px;
  }

  .input-box {
    height: 30px;
    width: 500px;
  }

  .input-box::placeholder {
    color: $red;
  }

  .form-btn {
    position: relative;
    height: 40px;
    bottom: 3px;

    &.true {
      padding-top: 200px;
      margin-bottom: 30px;
    }

    &.add{
      padding-top: 30px;
      margin-right: -10px;
    }

    .edit-loader {
      min-width: 120px;
    }

    &.old-client-id {
      padding-top: 40px;
    }
  }

  .input-tooltip-container {
    position: relative;
    bottom: 2px;
    margin-right: 30px;
    .input-tooltip {
      overflow-x: auto;
      white-space: initial;
    }
  }

  .loader-container {
    width: 150px;
  }

  .relative-loader {
    position: relative;
    height: 40px;
    left: 35px;
    bottom: 5px;
  }
  .response-description{
      margin-top: -10px;
      margin-bottom: -10px;
      margin-left: 50px;
  }
}
