@import '../../variables.scss';

.export-bau {
    margin-bottom: 12px;
    margin-right: 100px;
    .export-button-bau {
        background-color: transparent;
        padding: 5px 5px;
        cursor: pointer;
        margin-top: 3px;
        border: none;
        color: rgba(255, 255, 255, 0.6);
        font-family: $paragraph;
        font-size: 16px;
        line-height: 1.47;
        padding-bottom: 11px;
      }
    .relative-loader{
        position: relative;
        bottom: 5px;
    }
  }

.export {
    position: absolute;
    width: 30em;
    .export-container {
        padding-bottom: 3px;
        display: flex;

    .export-button {
        background-color: transparent;
        color: $white;
        padding: 5px 5px;
        cursor: pointer;
        margin-top: 3px;
    }

    .export-tooltip {
        margin-right: 40px;
        margin-top: -1px;
    }
    .export-toggle {
        cursor: pointer;
        background-color: transparent;
        white-space: nowrap;
        color: #FFFFFF99;
        border: none;
        font-family: $paragraph;
        font-size: 16px;
        line-height: 1.47;
    }
    .export-toggle-container {
        display: flex;
        margin-bottom: 10px;
        padding-bottom: 5px;
        .svg {
        transform: scale(1.8);
        }
    }
    .arrow-down-export {
        height: 20px !important;
        width: 20px !important;
        padding-bottom: 2px !important;
        color: #FFFFFF99;
        margin-top: 3px;
        padding-right: 1px;
        padding-top: 1px;
    }

    .arrow-up-export {
        height: 19px;
        width: 20px;
        padding-bottom: 1px;
        color: #FFFFFF99;
        margin-top: 5.1px;
    }

    .export-menu {
        background-color: #14373f;
        color: #fff;
        padding: 15px;
        border: 1px solid #616B6B;
        position: absolute;
        top: 40.5px;
        border-radius: 10px !important;
        overflow: auto;
        white-space: nowrap;
        max-width: 344px;
        &.false {
            display: none;
        }
        &.past-files {
            &.true {
                left: -5em;
            }
        }
        a { color: rgba(255, 255, 255, 0.75); }
    }

    .export-loading {
        color: rgba(255, 255, 255, 0.75);
        &.true {
        color: grey;
        }
    }

    .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        position: relative;
    }
    }
}