@import "../../variables";

.expanded-investigations-container {
  background-color: rgba(255, 255, 255, 0.1);
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid $darkgrey;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  .close-icon {
    position: absolute;
    cursor: pointer;
    z-index: 5;
    top: 14.5px;
    right: 10px;
  }

  .expanded-header {
    display: flex;
    justify-content: space-between;
    min-width: 36vw;
    margin: -10px 0 -30px 0;
    white-space: nowrap;
    letter-spacing: 3px;
    font-family: $heading;

    .severity-container {
      display: flex;
      align-items: center;
      justify-content: left;
      position: relative;
      margin: -3px 0 0 5px;
      height: 25px;
      border-radius: 5px;
      border: solid 0.75px transparent;
      border-right-style: hidden;
      letter-spacing: 1px;
      font-family: $paragraph;
    }

    .date-ticket {
      display: flex;
      justify-content: space-between;

      .ticket {
        color: $grey;
        margin-left: 10px;
      }
    }

    .status {
      font-family: $barlow-bold;
      margin-right: 25px;
      font-weight: 600;
    }
  }

  p {
    font-size: 14px;
  }

  td {
    font-size: 14px;
    overflow-wrap: anywhere;
  }

  .investigation-header {
    min-height: 340px;
    position: relative;
    .header-border {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 0 39%;
      margin: 0 -30px;
      min-width: 840px;
      max-width: 840px;
      height: 100%;
    }

    .loader-container {
      position: relative;
      right: 20px;
      bottom: 15px;
    }

    .o365-button-container {
      margin-left: 10em;
      &.target {
        margin-left: 7em;
      }

      .o365-response-button {
        background-color: transparent;
        border: 1px solid var(--ds-border-input, #6d7f83);
        box-sizing: border-box;
        cursor: pointer;
        color: white;
        font-family: $paragraph;

        &.reject {
          color: $grey;
          margin-left: 12px;
        }
      }
    }

    .header-content {
      position: absolute;
      max-width: 700px;
      max-height: 240px;
      top: 55px;
      padding-left: 20px;
      padding-right: 15px;
      display: flex;
      overflow-wrap: break-word;

      .threat-container {
        .threat-icon {
          float: left;
        }

        .threat-label {
          text-align: right;
          color: #7D9395;
          white-space: nowrap;
          text-align: left;
          margin-bottom: 2px;
        }

        .threat-value {
          margin-bottom: 2px;
        }
      }

      .target-container {
        margin-left: -16px;

        .target-icon {
          position: relative;
          float: left;
          width: 5.5em;
          margin-right: -15px;
          height: 70px;
          bottom: 12px;
        }

        .target-label {
          text-align: right;
          color: #7D9395;
          white-space: nowrap;
          text-align: left;
          margin-bottom: 2px;
        }
      }

      .threat-table {
        &.left {
          padding-left: 23px;
        }

        .label {
          text-align: right;
          color: #7D9395;
          white-space: nowrap;
          padding-right: 5px;
        }

        .value {
          padding-left: 10px;
          max-width: 14vw;
          word-break: break-all;
          overflow-x: hidden;
        }
      }
    }

    .detection-id {
      max-width: initial;

      .detection-link {
        color: white;
        white-space: nowrap;
      }
    }
  }

  .description {
    width: 100%;
    white-space: pre-wrap;
    word-break: break-word;

    hr {
      margin: 10px -70px;
    }
  }

  .status-container {
    margin-left: 20px;
  }

  .status-bar {
    text-align: center;
    background: #06373e;
    width: 75px;
    border-radius: 15px;
    line-height: 15px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    position: relative;
    top: -15px;
    margin: 30px 0 -20px 80px;
    padding: 2px;

     &.Waiting_On_Customer {
       height: 40px;
       top: -25px;
     }
     &.Waiting_On_ActZero {
       height: 40px;
       top: -25px;
    }
  }

  .comments {
    .user-comment {
      padding-top: 10px;
      font-family: $paragraph !important;

      form {
        display: contents;
      }

      .form-title{
        font-family: $roboto;
        color: $grey;
        font-size: 15px;
        margin-bottom: 5px;
      } 
    }

    .comment-label {
      padding-right: 10px;
    }

    img {
      position: relative;
      left: 48px;
      margin-right: 70px;
      width: 50px;
      margin-left: -60px;
      height: 28px;
    }

    .form-header{
      font-family: $roboto;
      color: $grey;
      margin-bottom: 5px;
    } 

    .add-comment-input {
      background-color: var(--ds-background-input, white);
      border: 1px solid var(--ds-border-input, #dfe1e6);
      box-sizing: border-box;
      height: 150px;
      padding-left: 20px;
      color: black;
      padding-top: 12px;
      padding-bottom: 12px;
      width: 100%;
      font-family: $paragraph;
    }

    .comment-submit-button {
      float: right;
      cursor: pointer;
      color: $grey;
      border: #617b80 3px solid;
      text-align: center;
      width: 105px;
      height: 30px;
      font-size: 16px;
      line-height: 25px;
      &:hover {
        color: $lightgrey;
        border-color: $lightgrey;
      }
    }

    .comment {
      color: $white;
      font-size: 16px;
    }
    .status {
      width: 24vw;
      white-space: break-spaces;
      color: $lightgrey;
    }
  }

  hr {
    border: 0.5px solid $darkgrey;
    margin-left: -20px;
  }

  .row {
    display: flex;
    overflow-x: hidden;

    &.comment-fields {
      font-size: 15px;
      color: $grey;
      display: inline;
      .comment-info{
         padding-bottom: 10px;
         padding-right: 2px;
         color: $grey;
         display: flex;
         .comment-label-value-author{
            display: flex;
            width: 81%;
            .comment-label-value{
              margin-right: 4px;
            }
            .comment-author{
              width: 60%;
              font-family: $roboto;
            }
         }
         .comment-time{
            width: 25%;
            text-align: end;
         }
      }
    }

    &.status {
      padding-left: 60px;
      .status-info{
        color: $grey;
        .status-label {
          float: left;
        }
        .status-value {
          float: left;
          color: $lightgrey;
        }
        .status-timestamp {
          text-align: end;
          float: right;
        }
      }
    }

    img {
      margin-right: 20px;
      width: 50px;
      margin-left: -60px;
      height: 50px;
    }
  }
}
