@import '../../variables';
@import './UpdatesVariables';

.updates-container {
    .table-header-container {
      .td-value {
          &.report_date{
            width: $time;
            margin-left: -4px;
            margin-right: 10px;
          }
          &.Hostname{
            width: $hostname;
          }
          &.Severity{
            width: $severity;
          }
          &.Title {
            width: $title;
          }
          &.Description {
            width: $description;
          }
      }
    }
    .no-results-text {
        display: flex;
        justify-content: left;
        margin-top: 2%;
        margin-left: 58px;
        font-size: 18px;
        color: #C0D0D0;
    }
    .gray-scale {
        opacity: 0.72;
        font-size: 16.16px;
        color: #788686;
        letter-spacing: -0.03px;
    }
    .table-row {
        height: 70px;
        &.true{
          padding-top: 20px;
          padding-bottom: 20px;
          align-items: flex-start;
          height: fit-content;
          background-color: rgba(255, 255, 255, 0.1);
        }
        .Severity{
            width: $severity;
            margin-right: 20px;
            margin-top: 20px;
            align-items: center;
            .severity-container {
               width: fit-content;
           }
           &.true{
             margin-bottom: 20px;
             margin-top: 0;
           }
          }
        .td-value{
            width: $default;
            font-weight: 300;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: flex;
            overflow: hidden;
            align-items: center;
            color: rgba(255, 255, 255, 0.8);
            margin-right: 20px;
            .expand-btn {
               cursor: pointer;
               position: absolute;
               z-index: 5;
               margin-left: -25px;
               margin-right: 8px;
               &.minus {
                 font-size: 30px;
                 margin-top: 0;
                 margin-right: 8px;
               }
            }
            &.report_date{
              display: flex;
              width: $time;
              margin-left: -4px;
              margin-right: 10px;
            }
            &.Source{
               width: $hostname;
            }
            &.Severity{
               width: $severity;
            }
            &.Title{
               width: $title;
               white-space: nowrap;
               text-overflow: ellipsis;
               display: flex;
               overflow: hidden;
            }
            &.Description{
               width: $description;
            }

            .td-top {
              font-weight: 350;
              color: rgba(255, 255, 255, 0.8);
              white-space: nowrap;
              width: unset;
              text-overflow: ellipsis;
              overflow: hidden;
              align-items: center;
              &.left {
                  padding-left: 8em;
              }
              .highlight-text {
                  color: #C0D0D0;
              }
              &.true {
                  display: flex;
                  align-items: center;
                  white-space: pre-line;
                  overflow-wrap: break-word;
                  padding-bottom: 11px;
              }
              &.expanded {
                  opacity: 1;
              }
            }
        }
        .left-row {
            display: flex;
            margin-right: 40px;
            &.severity {
                margin-right: 0;
            }
            .td-top {
                font-weight: 350;
                font-size: 16.16px;
                color: #C0D0D0;
                letter-spacing: -0.03px;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 12px 0 -5px 10px;
                &.expanded {
                    color: rgba(255, 255, 255, 0.6);
                }
            }
            .td-bottom {
                font-size: 16.16px;
                color: #8CA3A3;
                letter-spacing: -0.05px;
                margin: 5px 10px;
                display: flex;
                flex-wrap: nowrap;
                text-overflow: ellipsis;
                &.expanded {
                    color: rgba(255, 255, 255, 0.6);
                }
            }
        }
        .right-row {
            .table-cell {
                display: flex;
                flex-direction: column;
            }
            .td-top {
                width: 500px;
                font-weight: 350;
                margin-right: 50px;
                color: rgba(255, 255, 255, 0.6);
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-bottom: 0;
                &.left {
                    padding-left: 8em;
                }
                .highlight-text {
                    color: #C0D0D0;
                }
                &.true {
                    white-space: pre-line;
                    overflow-wrap: break-word;
                    padding-bottom: 11px;
                }
                &.expanded {
                    opacity: 1;
                }
            }
            .td-bottom {
                opacity: 0.61;
                font-size: 16.16px;
                color: #C0D0D0;
                letter-spacing: -0.03px;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: inline-block;
                overflow-x: hidden;
                &.true {
                    white-space: pre-line;
                    overflow-wrap: break-word;
                    padding-bottom: 11px;
                }
                &.expanded {
                    opacity: 1;
                }
            }
        }
    }
    .updates-pagination {
        margin-left: 56px;
    }
}

.updates {
    height: unset;
}