.time-value {
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 0;
  color: #cfcdcd;
}

.slider {
  .parameter-value {
    outline:0;

    path {
      outline:0;
    }
  }
}
