@import '../../variables';
@import './MaturityAssessmentVariables';

.maturity-assessment-page-container {
  display: flex;
  flex-direction: column;

  .pagination-controls {
    margin: 0 60px 20px 60px;
    padding-right: 50px;

    .reset-filters-button {
      margin: 0 0 -35px 45px;
    }
  }

  .maturity-assessment-table-container {
    display: flex;

    .page-loading {
      &.relative-loader {
        height: 85vh;
      }
    }

    .line-filter {
      width: 100vw;
    }

    .no-results-text {
      display: flex;
      justify-content: left;
      margin-top: 2%;
      margin-left: 58px;
      font-size: 18px;
      color: $grey;
    }

    .table-container {
      &.expanded {
        height: 45vw;
        overflow-y: scroll;


        .table-header-container {
          .td-value {
            &.control {
              min-width: $expanded-control;
              width: $expanded-control;
            }

            &.tool {
              min-width: $expanded-tool;
              width: $expanded-tool
            }

            &.name {
              min-width: calc(55px + $expanded-name);
              width: calc(55px + $expanded-name);
            }

            &.difficulty {
              min-width: $expanded-difficulty;
              width: $expanded-difficulty;
            }

            &.status {
              min-width: $expanded-status;
              width: $expanded-status;
            }
          }
        }
      }
      &.expanded::-webkit-scrollbar {
        display: none;
      }
    }

    .maturity-assessment {
      .table-header-container {
        .td-value {
          margin-right: 20px;

          &.control {
            min-width: $control;
            width: $control;
          }

          &.category {
            min-width: $category;
            width: $category;
          }

          &.tool {
            min-width: $tool;
            width: $tool;
          }

          &.name {
            min-width: calc(55px + $name);
            width: calc(55px + $name);
          }

          &.difficulty {
            min-width: $difficulty;
            width: $difficulty;
          }

          &.status {
            width: $status;
          }
        }
      }
    }
  }
}
