@import "../../../variables";

.fdg-container {
  position: relative;
  overflow: hidden;
  min-height: 650px;
  height: 95vh;
  font-family: $paragraph;
  overscroll-behavior: contain;

  .tooltip-container {
    position: absolute;
    right: 15px;
    top: 5px;
    z-index: 99;
    .tooltip {
      margin-top: 30px;
      font-size: 12px;
    }
  }

  .node-text {
    font-family: $paragraph;
    font-size: 0.16px;
    top: -80px;
    pointer-events: none !important;
    -webkit-text-stroke: 0.0025px #0e2124;
    &.highly_connected_ip {
      top: -125px;
    }
  }
}
.fdg {
  border: 1px solid #1e1e1e;
  box-shadow: -1px -3px 19px 0px rgba(33, 33, 33, 0.75) inset;
  -webkit-box-shadow: -1px -3px 19px 0px rgba(33, 33, 33, 0.75) inset;
  -moz-box-shadow: -1px -3px 19px 0px rgba(33, 33, 33, 0.75) inset;
  background-color: #0e2124;

  .labels {
    text {
      &:focus {
        outline: none;
      }
    }
  }

  .links {
    line {
      opacity: 0.8;
      cursor: pointer;

      &.selected-link {
        opacity: 1;
      }

      &:focus {
        outline: none;
        opacity: 1;
      }
    }
  }

  .link-label {
    .link-item {
      &:focus {
        outline: 2px solid rgba(140, 163, 163, 0.5);
        outline-offset: 2px;
      }

      &:active {
        outline: 2px solid rgba(140, 163, 163, 0.5);
        outline-offset: 2px;
      }
    }

    .selected-link-label {
      outline: 2px solid rgba(140, 163, 163, 0.5);
      outline-offset: 2px;
      border-right: 20px;
      background-color: #0d2124;
    }
  }

  .nodes {
    .node-item {
      stroke-width: 1.5px;

      &.selected-node {
        stroke: #fff;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .icons {
    image {
      &:focus {
        text-decoration: none;
        outline: 0;
      }
    }
  }
}

.knowledge-graph-container {
  .zeroin-loader {
    position: absolute;
    display: flex;
    min-height: 800px;
    padding-right: 250px;
    width: 100vw;
  }
}
