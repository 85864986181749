@import '../../../variables';

.expanded-container {
  background-color: #2c4f55;
  justify-content: space-between;

  .vulnerability-data-table {
    width: 540px;
    padding-left: 50px;

    .threat-table {
      color: $grey;
      word-break: break-word;
      width: fit-content;

      &.left {
        margin: 20px 0 25px 0;

        .no-value {
          color: #7D9395;
        }

        .Description {
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;

          &.pointer {
            cursor: pointer;
          }
        }

        .Confidence {
          cursor: pointer;
          .tooltip-icon-small {
            width: 16px;
          }
          .confidence-value {
            padding-right: 7px;
            &.High {
              color: $green;
            }
            &.Medium {
              color: $yellow-01;
            }
            &.Low {
              color: $orange;
            }
          }
        }

        .expand-description {
          cursor: pointer;
          vertical-align: bottom;
          color: #7D9395;
          font-weight: bold;
          word-break: normal;
        }
      }
    }
  }

  .title {
    color: rgb(255, 255, 255);
    font-size: 16.16px;
    line-height: 31px;
    font-weight: bold;
    text-align: right;
    margin-bottom: 0;
    padding-right: 10px;
    white-space: nowrap;
  }

  .label {
    text-align: right;
    color: #7D9395;
    white-space: nowrap;
    padding-right: 10px;
    font-weight: bold;
    vertical-align: top;
    width: 165px;
  }

  .cve-label {
    text-decoration: none;
    color: $grey;
    &:hover {
      color: $yellow;
    }
  }

  .value {
    padding-left: 10px;
    max-width: 14vw;
    word-break: break-all;
    overflow-x: hidden;
  }

  .assets-and-details {
    margin-left: 5%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .dropdown-container {
      &.global-action-dropdown {
        width: 305px;
        margin-left: auto;
        padding-left: 17px;

        &.open {
          width: 130px;
          margin-right: 160px;
        }
      }
    }

    .assets-pagination {
      align-items: baseline;
      height: 55px;

      .results {
        font-family: $paragraph;
        font-size: 14px;
        margin-right: 20px;
        color: #748e92;
      }
    }

    .pagination {
      display: flex;
      list-style: none;
      cursor: pointer;
      padding-left: 0;

      li {
        padding: 5px;
        color: $white;
        font-weight: 100;
      }

      .pagination-disabled {
        color: $darkgrey;
        cursor: auto;
      }

      li {
        color: $grey;
      }

      .pagination-active {
        color: $white;
        font-weight: bolder;
      }

      a {
        font-size: 14px;
        font-family: $roboto-bold;
        margin: 0 -2px;
      }
    }

    .tab-container {
      height: 45px;
      position: relative;
      bottom: -9px;
      margin: 20px 0 0 30px;

      .tab {
        padding: 10px 20px 5px 19px;
        border-color: #35555a;

        &.selected {
          background-color: #325259;
          border: 1px solid $darkgrey;
          border-top: none;
          border-bottom: none;

          .tab-text {
            color: white;
          }

          .tab-total {
            color: white;
          }
        }
      }

      .tab-text {
        margin: -5px 0 -18px;
        height: 40px;
        color: rgba(255, 255, 255, 0.8);
        font-family: $subtitle;
        font-size: 15px;
        letter-spacing: 1.76px;
        white-space: nowrap;

        .tab-total {
          font-size: 22px;
          font-weight: 200;
          color: rgba(255, 255, 255, 0.5);
          margin-right: 6px;
        }
      }
    }

    .table-container {
      padding: 50px;
      border: 1px solid $darkgrey;
      margin-top: -5px;
      flex: 1;
      border-bottom: none;
      border-right: none;
    }

  }
}
