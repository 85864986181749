.connections-row {
  &.log {
    padding-bottom: 0;

    .count {
      display: flex;

      .size {
        font-size: 19px;
        align-self: flex-end;
        padding-bottom: 7px;
      }
    }

    .service-icons {
      margin-left: -30px;
      width: 80px;
      margin-right: -14px;
    }

    .trend-container {
      right: 3.3em;
      top: 5em;
    }

    .connection-data {
      width: 450px;
      margin-bottom: 10px;
      justify-content: flex-start;

      .Contracted {
        margin-top: 15px;
        margin-left: 112px;
        white-space: nowrap;
      }
    }

    .modal-btn {
      text-align: left;
      margin-top: 50px;
      cursor: pointer;
    }
  }
}