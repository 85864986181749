@import '../../variables';
@import './TicketVariables';

.tickets-page-container {
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: #14373f;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.4);
  }

  .loader-container {
    height: 85vh;
  }
  .table-icon {
    &.delete {
      width: 35px;
    }
  }
  .no-results-text {
    display: flex;
    justify-content: left;
    margin-top: 2%;
    margin-left: 2%;
    font-size: 18px;
    color: #C0D0D0;
  }
  .fixed-table-container {
    display: flex;
  .ticket-table-container {
     display: flex;
     max-height: 85vh;
     width: 100%;
     &.expanded {
       width: fit-content;
     }
     tr{
      overflow: hidden;
     }
    .tickets {
      &.expanded {
        min-width: 1065px;
        width: 60vw;
        overflow-y: scroll;
        overflow-x: hidden;
          .table-row {
            overflow: hidden;
            .td-value {
              &.case_number {
                min-width: $expanded_case_number;
                max-width: $expanded_case_number;
              }
              &.created_date {
                min-width: $expanded_created_date;
                max-width: $expanded_created_date;
              }
              &.priority {
                min-width: $expanded_priority;
                max-width: $expanded_priority;
              }
              &.email {
                min-width: $expanded_submitted_by;
                max-width: $expanded_submitted_by;
              }
              &.status {
                min-width: $expanded_status;
                max-width: $expanded_status;
              }
            }
          .table-header-container {
            &.tickets {
              .td-value {
                &.case_number {
                  min-width: $expanded_case_number;
                  max-width: $expanded_case_number;
                }
                &.created_date {
                  min-width: $expanded_created_date;
                  max-width: $expanded_created_date;
                }
                &.priority {
                  min-width: $expanded_priority;
                  max-width: $expanded_priority;
                }
                &.email {
                  min-width: $expanded_submitted_by;
                  max-width: $expanded_submitted_by;
                }
                &.status {
                  min-width: $expanded_status;
                  max-width: $expanded_status;
                }
              }
            }
          }
        }
        .status-button{
          margin-left: -60px;
        }
      }
      &.table-container {
        .table-row {
          align-items: center;
          font-weight: 300;
          color: rgba(255, 255, 255, 0.8);
          text-overflow: ellipsis;
          .case_number {
            display: flex;
            width: $case_number;
            margin-left: -5px;
            margin-right: 20px;
          }
          .created_date {
            display: flex;
            width: $created_date;
            margin-left: -5px;
            margin-right: 20px;
          }
          .priority {
            width: $priority;
            margin-left: -5px;
            margin-right: 20px;
          }
          .submitted-by {
            width: $submitted_by;
            margin-left: -5px;
            margin-right: 20px;
            word-break: break-all;
          }
          .status-bar {
            width: $status;
            margin-left: -5px;
            margin-right: 20px;
            .status-bar-container {
              margin-top: 40px;
            }
          }
          .row-buttons{
            position: relative;
            left: 75px;
            .table-icon {
              &.edit {
                margin-right: -5px;
              }
              &.delete {
                width: 35px;
              }
            }
          }
        }
        .table-header-container {
          &.tickets {
          .td-value {
            &.case_number {
              display: flex;
              width: $case_number;
              margin-left: -5px;
              margin-right: 20px;
            }
            &.created_date {
              display: flex;
              width: $created_date;
              margin-left: -5px;
              margin-right: 20px;
            }
            &.priority {
              width: $priority;
              margin-left: -5px;
              margin-right: 20px;
            }
            &.email {
              width: $submitted_by;
              margin-left: -5px;
              margin-right: 20px;
            }
            &.status {
              width: $status;
              margin-left: -5px;
              margin-right: 20px;
            }
          }}
        }
      }
    }
  }
  }
  .create-button{
    position: relative;
    left: 50px;
  }
  .edit-tooltip{
    width: 37px;
  }
  .status-modal{
    .cancel, .confirm{
      top: 25px;
    }
  }
  .status-button{
    width: 80px;
    font-size: 15px;
    border-width: 2px;
    padding-top: 1px;
    
    &:hover{
      color: $lightgrey;
      border-color: $lightgrey;
    }
  }
  .support-number{
    position: absolute;
    color: $lightgrey;
    right: 30px;
    top: 90px;
    .label{
      color: $grey;
    }
  }
}

@media (max-width: 1680px) {
  body {
    overflow: scroll;
  }

  .tickets-page-container {
    width: 1680px;
  }
}