@import "../../variables";

.loader-btn-container {
  .loader-btn {
    font-family: $paragraph;
    cursor: pointer;
    color: $grey;
    border: #617b80 3px solid;
    text-align: center;
    width: 105px;
    height: 30px;
    font-size: 16px;
    line-height: 25px;
    user-select: none;
    .relative-loader {
      width: 50px;
    }
    &:hover{
      color: $lightgrey;
      border-color: $lightgrey;
    }
  }
  .relative-loader{
    margin-left: 27px;
  }
}
