@import '../../variables';

.confirm-modal {
  .modal-container {
    min-width: 200px;
    width: fit-content;
    position: fixed;
    left: 49%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.errors {
    .modal-container {
      width: 400px;
    }

    .modal-data {
      height: 260px;
    }

    .close-button-container {
      display: flex;
    }

    li {
      list-style-type: none;
    }

    .error-msg {
      margin-top: 20px;
      margin-right: 20px;
      margin-left: 20px;
    }

    ul {
      :first-child {
        font-size: 15px;
      }

      font-size: 14px;
    }
  }

  .modal-data {
    height: 80px;
  }

  .modal-data::-webkit-scrollbar {
    display: none;
  }

  .close-button-container {
    display: none;
  }

  .confirm-dialog {
    position: relative;
    top: 12px;
    width: fit-content;
    margin: auto;
    color: #c0d0d0;
    padding-left: 10px;
    padding-right: 10px;

    .confirm {
      position: absolute;
      top: 34px;
      left: calc(50% - 35px);

      :hover {
        color: $white;
      }
    }

    .cancel {
      position: absolute;
      top: 34px;
      right: calc(50% - 35px);

      :hover {
        color: $white;
      }
    }

    &.error {
      color: $red;

      .err-icon {
        position: relative;
        top: 2.5px;
        margin-right: 4px;
        color: $red;
      }
    }
  }

  .relative-loader {
    position: relative;
    margin: auto;
    height: 100%;
    width: 47px;
    top: 15px;
  }

  &.cloud-modal {
    .relative-loader {
      left: 75px;
    }

    .error {
      padding: 10px;
    }
  }
}
