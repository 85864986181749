@import '../../variables';

.top-hygiene-issues {
  margin-left: 0px;
  padding-right: 4em;
  padding-left: 2em;

  .title {
    font-size: 14px;
    margin-bottom: 5px;
  }


  .top-amount {
    text-align: right;
    color: #929292;
    padding-right: 5px;
  }

  .top-item {
    margin-bottom: 0;
    font-weight: 200;
    vertical-align: top;

    .top-name:hover {
      color: $yellow;
    }

    .top-name {
      display: flex;
      padding-right: 0px;
      padding-left: 5px;
      cursor: pointer;
      .tooltip-issues-value {
        text-overflow: ellipsis;
        max-width: 450px;
        display: block;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .top-box {
      width: 26em;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}