.connections-row {
  &.endpoint {
    height: 170px;

    .service-icons {
      span {
        margin-left: -5px;
      }
    }
  }
}
