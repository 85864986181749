@import '../../variables.scss';

.time-dropdown-container {
  .time-and-search {
    display: flex;
    color: #C0D0D0;
    width: 310px;
    position: relative;
    &.page-filter {
      margin-top: 11px;
    }
    &.log-search {
      padding-top: 10px;
      padding-bottom: 15px;
      margin: 10px;
    }
  }

  .time-row-container {
    display: flex;
    font-size: 15px;
  }

  .MuiIconButton-label {
    margin: 0 !important;
  }

  .radio-container {
    display: flex;
    align-items: center;
    &.page-filter {
      margin: -10px 0 3px 0 !important;
    }
    &.true {
      background-image: linear-gradient(#C0D0D0, transparent);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .MuiSvgIcon-root {
    color: #C0D0D0;
    width: 1em;
    height: 0.56em;
  }

  .MuiIconButton-root {
    padding-left: 0;
  }
  
  .time-row {
    margin: 0 15px;
    &.end-time {
      margin-left: 1.07em !important;
    }
    &.start-time {
      margin-left: 0 !important;
      &.true {
        background-image: linear-gradient(#C0D0D0, transparent);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .time-filter-col > * {
    font-size: 17px;
    margin: -10px 0 10px 0;
    white-space: nowrap;
    cursor: pointer;
  }

  .time-title-text {
    margin: 0 !important;
    padding-bottom: 15px;
    white-space: nowrap;
    font-family: $paragraph;
    font-size: 16px;
    line-height: 1.47;
    cursor: default;
    color: #C2D3D3;
    &.page-filter {
      padding-bottom: 5px;
      &.true {
        background-image: linear-gradient(#C0D0D0, transparent);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .time-text {
    color: #a9abab;
    font-size: 12px;
    font-weight: 100;
    margin-left: 10px !important;
    width: 50%;
    margin-top: -5px !important;
    .bold-text {
      color: $grey;
      font-family: $roboto-bold;
    }
  }

  .date-title {
    margin: 10px 0 0 0;
  }


  .MuiRadio-root {
    padding-right: 2px !important; 
  }

  .time-module-container {
    padding-left: 25px;
    &.page-filter {
      padding-top: 23px;
    }
    &.log-search {
      padding-top: 32.5px;
    }
  }

  .MuiTextField-root {
    height: 2.5em;
    width: 8em;
  }

  .quick-time-search-filters {
    width: fit-content;
    cursor: pointer;
    font-family: $paragraph;
    font-size: 15px;
    line-height: 1.47;
    color: white;
  }
}