@import '../../variables';

.hygiene-labeled-expanded-view-container {
  background-color: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: row;
  cursor: default;

  .expanded-item {
    color: $grey;
    &.left {
      height: 0px;
      padding-left: 80px;
    }
  }

  .right-expanded-items {
    display: flex;
  }

  .right-table {
    max-width: 53%;
  }
  
  .expanded-item-value {
    vertical-align: top;
    max-width: calc(6em * 5);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    .compliance-list {
      padding-left: 18px;
    }
    .compliance-item {
      margin-bottom: 0px;
    }
    .expanded-box {
      width: 28em;
      .clickable-link {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.7);
        cursor: pointer;
      }
      .clickable-link:hover {
        color: $yellow;
      }
    }
  }

  .expanded-item-title {
    text-align: right;
    padding-right: 10px;
    color: rgba(255, 255, 255, 0.4);
    font-size: 16.16px;
    margin-right: 20px;
    white-space: nowrap;
    vertical-align: top;
  }

  .left-container {
    flex: 1 1;
    padding-top: 10px;
    overflow-y: scroll;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 40px;
    &.border {
      border-right: 1px solid $darkgrey;
    }
  }

  .right-container {
    flex: 1 1;
    overflow-y: scroll;
    max-width: 53%;
    border: none;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 60px;
    padding-right: 100px;
  }
  
  .link-arrows{
    font-size: 18px;
    line-height: 16px;
  }
}