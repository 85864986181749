@import '../../variables';

.vulnerabilities-over-time {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 270px;
  padding-top: 35px;

  &.disabled {
    opacity: 0.5;
  }

  .tooltip {
    &.vulns-title-tooltip {
      left: 530px;
      top: -60px;
    }
    &.vulns-percent-tooltip {
      left: 135px;
      top: 70px;
    }
  }

  .loader-container {
    position: relative;
    left: 45%;
  }

  .percent-complete {
    cursor: pointer;

    &:hover {
      font-weight: 700;
    }
  }

  .vulns-over-time-title {
    border: 1.5px solid #75cbc0;
    border-radius: 20px;
    letter-spacing: 2px;
    background-color: #0f3137;
    font-family: $heading;
    position: relative;
    margin-right: -228px;
    left: 234px;
    top: -75px;
    padding: 2px 10px;
    z-index: 0;
    white-space: nowrap;

    span {
      color: #8a8f92;
    }
  }

  .dashboard-vulns-graph {
    background-size: 103.5%;
    background-repeat: no-repeat;
    background-position: center center;
    background-position-y: -9px;
    background-position-x: 0;
    width: 220px;
    padding: 30px;
    margin-left: 10px;
    position: relative;
    height: 205px;

    .total-count {
      letter-spacing: -1px;
      font-weight: 300;
      display: flex;
      justify-content: center;
      position: absolute;
    }

    .vulns-arrow {
      width: 16px;
      left: 113px;
      position: relative;
      bottom: 90px;
    }

    .dashboard-vulns-container {
      margin-left: 28px;
    }

    .dashboard-vulns-chart {
      margin-left: -25px;
    }
  }

  .d3-tooltip {
    position: absolute;
    left: 35px;
    top: -7px;
    padding: 8px;
    color: $white;
    background: #365b62;
    font-size: 14px;
    line-height: 1.2;
    font-family: $paragraph;
    z-index: 99;
    white-space: nowrap;
    width: fit-content;
    border: 1px solid #5f7f84;
    letter-spacing: 0.1px;
  }

  .d3-tooltip-mouseover {
    position: absolute;
    width: 25px;
    height: 25px;
  }

  .upsell-message {
    margin-left: 10vw;
  }
}

@media (min-width: 2300px) {
  .vulnerabilities-over-time {
    .dashboard-vulns-graph {
      margin-right: 6%;
    }
  }
}
