@import "../../variables";

.map {
  overflow: hidden;

  &.incoming {
    border-bottom: 1px solid #3e595d;
    margin-bottom: 15px;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
  }

  path {
    fill: #113a42;
    stroke: #526f74;
    stroke-width: 1px;
    opacity: 0.9;
  }

  .projection-container {
    position: relative;
    top: -110px;
    width: 100%;
    min-width: 1000px;
    max-height: 40vh;
    z-index: 9;
  }

  .map-title {
    font-family: $heading;
    position: relative;
    top: 20px;
    z-index: 10;
    margin-left: 50px;
    width: 170px;
    letter-spacing: 1.5px;
  }

  .country-circle {
    fill: #037172;
    opacity: 0.7;
    border: 1px #526f74;
    stroke: $grey;
    stroke-width: 0.5px;

    &:hover {
      opacity: 1;
    }
  }

  .country-marker {
    text {
      fill: $white;
    }
  }

  &.outgoing {
    path {
      fill: #122a2f;
      stroke: #526f74;
    }

    .country-circle {
      fill: #266e51;
    }
  }

  .country-tooltip-container {
    position: absolute;
    width: fit-content;
    height: fit-content;
    background-color: #174952;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: $lightgrey;
    font-size: 16px;
    z-index: 11;

    .country-name {
      float: left;
      margin-right: 20px;
    }

    .tooltip-right {
      float: left;
    }

    .country-allowed {
      color: $grey;
      font-size: 14px;
    }
  }
}
