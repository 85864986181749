.connections-page-container {
  .connections-row {
    &.mobile {
      height: 170px;
      .trend-container {
        top: 8.5em;
      }
      .service-icons {
        margin-left: -16px;
      }
    }
  }
  .service-count{
    margin-left: 8px;
  }
}