@import '../../variables';
@import './DetectionsVariables';

.detections-page-container {
  .table-header-container {
    .td-value {
      margin-right: 0;

      &.Time {
        width: 18%;
      }

      &.Source {
        width: 25.7%;
      }

      &.Severity {
        width: 12.5%;
      }

      &.Type {
        width: 46%;
      }

      &.Resource {
        margin-left: -70px;
        width: 25%;
      }

      &.Action {
        width: 8.5%;
      }

      &.Number {
        width: 9%;
        margin-right: 1em;
      }
    }
  }

  .no-results-text {
    display: flex;
    justify-content: left;
    margin-top: 2%;
    margin-left: 2%;
    font-size: 18px;
    color: $grey;
  }

  .header-row {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    .page-title {
      width: 200px;
    }
  }

  .loader-container {
    height: 19em;
    display: flex;
    justify-content: center;
  }

  .top-container {
    display: flex;
    max-height: fit-content;
    height: 19em;

    &.true {
      height: 4.8em;
      overflow: hidden;
      cursor: pointer;
    }

    .graph-container {
      align-items: center;
      padding-bottom: 2em;

      &.right {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0)) 1 100%;

        &.no-data {
          width: 38em;
        }
      }

      &.no-data {
        width: 38em;
      }

      .legend-container {
        overflow: visible;
        display: flex;
        position: relative;
        left: 3.4em;
        bottom: 29px;
        padding-bottom: 2.3em;

        &.right {
          top: 14px;
          bottom: 0;
          left: 3em;
        }
      }

      .no-data {
        text-align: center;
        font-size: 36px;

        &.right {
          padding-top: 1.14em;
        }
      }

      .title-and-legend {
        display: flex;
        margin-top: 30px;

        &.right {
          margin-top: 26px;
        }

        &.true {
          padding-bottom: 3em;
        }

        .expand-btn {
          padding-left: 34px;
          font-size: 39px;
          position: relative;
          bottom: 21px;
          color: $grey;
          cursor: pointer;

          &.true {
            font-size: 21px;
            bottom: 5px;
            background-image: linear-gradient($grey, transparent);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .graph-title {
          white-space: nowrap;
          font-weight: 450;
          font-family: $heading;
          margin: 0 -200px -10px 10px;
          letter-spacing: 1.5px;
          position: relative;
          bottom: 5px;

          &.right {
            padding-left: 34px;
            padding-bottom: 5px;
            bottom: 1px;
          }

          &.true {
            background-image: linear-gradient($grey, transparent);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        svg {
          margin-top: -5px;
        }
      }

      .severity-line-chart {
        float: left;
      }

      .source-line-chart {
        float: right;
      }

      .trend-graph {
        margin-top: -55px;
        margin-left: 25px;

        &.right {
          margin-top: -11px;
        }
      }
    }
  }

  .table-row {

    &.expanded {
      background-color: rgba(255, 255, 255, 0.1);
    }

    .Action {
      &.td-value {
        width: $action;
        margin-right: 0;
        margin-left: 24px;
      }
    }

    .Severity {
      width: $severity;
      margin-right: 20px;
      margin-top: 20px;

      .severity-container {
        width: fit-content;
      }
    }

    .td-value {
      display: flex;
      align-items: center;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.8);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-right: 20px;

      .expand-btn {
        cursor: pointer;
        position: absolute;
        z-index: 5;
        margin-left: -25px;
        margin-right: 8px;

        &.minus {
          font-size: 30px;
          margin-top: 0;
          margin-right: 8px;
        }
      }

      &.Time {
        display: flex;
        width: $time;
        margin-left: -4px;
        margin-right: -15px;
      }

      &.Source {
        width: $source;

        .Directory {
          width: 45px;
        }

        .icon-container {
          min-width: 100px;
          text-align: center;
          margin-right: -15px;
          margin-left: 10px;
          .source-icon-SentinelOne {
            padding: 10px;
          }
          .source-icon-Defender {
            padding: 10px;
          }
        }
      }

      &.Detection {
        width: $detection;
        margin-right: 20px;
      }

      &.Type {
        width: $detection;
      }

      &.Resource {
        width: $resource;
        margin-right: 0;
        margin-left: -72px;
      }

      &.Case {
        width: $case;
        margin-right: 1.8em;
      }

    }

    .clickable-link {
      background: none !important;
      border: none;
      padding: 0 !important;
      cursor: pointer;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.8);
      font-family: $button;
      font-size: 16px;
    }

    .clickable-link:hover {
      color: $yellow;
    }
  }
}

.line-filter {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2%;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
}

@media (max-width: 1680px) {
  body {
    overflow: scroll;
  }
  .detections-page-container {
    width: 1680px;
  }
}

@media (min-width: 1680px) {
  .top-container {
    .data-list-container-detections {
      .table-container {
        right: -5%;
      }
    }
  }
}