@import '../../variables';

.multitenant-dashboard-container {
  .right-dashboard {
    .graph-container {
      .detections-panel {
        justify-content: space-between;

        .legend-container {
          margin-top: -20px;
        }
      }
    }
  }
}
