@import "../../../variables";

.tenant-details-modal-container {
  .modal-container {
    width: fit-content;
    position: fixed;
    left: 49%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .modal-header {
    padding-left: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid $darkgrey;
    width: 100%;
    font-weight: 600;
    position: relative;
    margin-top: 10px;
    font-family: $heading;
    font-size: 17px;
  }
  .modal-data {
    height: fit-content;
    padding-bottom: 10px;
    padding: 0px;
    overflow: hidden;
    width: 600px;
  }
  .modal-content {
    position: relative;
    padding-left: 20px;
  }
  .modal-input-container {
    margin-top: 25px;
    margin-bottom: 10px;
    width: 100%;
    list-style-type: none;
    padding-inline-start: 20px;
    font-family: $paragraph;
    li {
      padding-bottom: 10px;
    }
    .input-label {
      padding-top: 2px;
      font-size: 15px;
      font-family: $paragraph;
      color: $grey;
      float: left;
      width: 150px;
      &.read-only {
        padding-top: 0;
      }
    }
    .input-field-container {
      position: relative;
    }
    .input-field {
      height: 25px;
      border-radius: 3px;
      border: 1px solid $darkgrey;
      padding-left: 7px;
      font-size: 15px;
      color: $black;
      &.text {
        width: 400px;
      }
      &.read-only {
        border: 0;
        color: $lightgrey;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .loader-btn-container {
    float: right;
    margin-right: 28px;
    margin-bottom: 15px;
    margin-top: 10px;
    .relative-loader {
      position: relative;
      margin-right: 20px;
    }
  }
  .save-btn {
    font-size: 15px;
    width: 100px;
  }
  .modal-dropdown-container {
    position: relative;
    .dropdown-container {
      position: fixed;
      min-width: 70px;
      border: 1px $grey solid;
      padding-left: 10px;
      padding-top: 5px;
      left: 172px;
      .dropdown-title {
        height: 15px;
        color: $lightgrey;
      }
      background-color: $darkturquoise;
      font-size: 15px;
      z-index: 1;
    }
    .list-item {
      background-color: $darkturquoise;
      &.hover {
        color: $white;
      }
    }
  }
  .loader-container {
    height: 275px;
    .relative-loader {
      position: relative;
      top: 100px;
    }
  }
  .submit-error {
    color: $red;
    margin-top: 3px;
    position: absolute;
    padding-left: 50px;
    z-index: 10;
    right: 140px;
    bottom: 20px;
    font-size: 15px;
    &.text-wrapped{
      bottom: 5px;
      font-size: 14px;
    }
    .err-icon {
      position: relative;
      color: $red;
      margin-right: 3px;
      top: 2.5px;
    }
  }
  .error-img {
    position: relative;
    left: 100px;
    top: 30px;
    width: 400px;
  }
  .modal-toggle-container {
    position: relative;
    left: -10px;
    margin-bottom: -5px;
    .modal-toggle {
      margin-top: -5px;
    }
  }
}
