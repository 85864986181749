@import '../../../variables';

.funds-allocated-meter {
  .background {
    fill: transparent;
  }

  .fill-color {
    fill: #ffe133;
  }

  text {
    &.percent-complete {
      font-size: 30px;
      fill: #ffe133;
      font-weight: 200;
      letter-spacing: -.03em;
    }

    &.description {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 11px;
      fill: #9B9B9B;
    }
  }
}
