@import '../../variables';

.form-group {
  display: flex;
  align-items: baseline;

  .switch-stack {
    display: flex;
    align-items: center;
    gap: 8px;

    .label {
      font-size: 14px;
    }

    .switch {
      position: relative;
      width: 28px;
      height: 16px;

      input {
        display: none;
      }

      .slider {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $darkgrey;
        border-radius: 16px;
        transition: background-color 0.4s;

        &::before {
          content: '';
          position: absolute;
          height: 12px;
          width: 12px;
          left: 2px;
          bottom: 2px;
          background-color: $white;
          border-radius: 50%;
          transition: transform 0.4s, width 0.2s;
        }
      }

      input {
        &:checked + .slider {
          &::before {
            transform: translateX(12px);
          }
        }

        &:active + .slider::before {
          width: 15px;
        }
      }
    }
  }
}
