.zoom-container {
  position: absolute;
  bottom: -90px;
  right: 10px;

  .zoom-button {
    position: relative;
    cursor: pointer;
    width: 30px;
    z-index: 12;
    float: left;

    .icon {
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
