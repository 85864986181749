@import '../../../variables';

.assets-and-details {
  .custom-checkbox {
    &:not(.Mui-checked) {
      .MuiSvgIcon-root {
        fill: #748e92;
      }
    }
  }

  .dropdown-container {
    &.global-action-dropdown {
      &.open {
        width: unset;
        position: relative;
        background-color: #14373f;
        border: 1px solid #616B6B;
        padding: 5px 10px 10px;
        white-space: nowrap;
        border-radius: 2px;
        z-index: 25;
      }
    }

    &.dropdown-sort {
      margin: -5px 0 0;
      white-space: nowrap;
      width: 120px;

      .custom-checkbox {
        margin-right: 5px;
      }

      .page-loading {
        &.relative-loader {
          position: relative;
        }
      }
    }

    &.action-dropdown {
      width: 90px;

      &.open {
        width: 112px;
        position: relative;
        background-color: #14373f;
        border: 1px solid #616B6B;
        padding: 5px 10px 10px;
        border-radius: 2px;
        z-index: 100;
      }
    }
  }

  .vulnerabilities-tab-table-container {
    &.vulnerabilities {
      margin-bottom: 200px;

      .table-body {
        .table-row {
          cursor: pointer;
          border-bottom: 1px solid $darkgrey;
          padding: 0;
          height: 30px;
          background-color: transparent;

          &:hover {
            background-color: transparent;
            color: $yellow;
          }

          &.headers {
            height: 23px;
            font-size: 13px;
            border-top: none;
            margin-top: -25px;
            color: #748e92;

            .caret {
              margin: 0 0 1px 5px;
            }

            .action_taken {
              margin-left: auto;
              width: 305px;

              .custom-checkbox {
                margin-right: 5px;
                cursor: pointer;
              }
            }
          }

          td {
            vertical-align: middle;

            &.Action {
              width: 205px;
              margin-left: auto;
            }

            &.comment {
              font-size: 12px;
              white-space: nowrap;
              cursor: pointer;
              margin-top: 4.5px;
              color: #748e92;
              padding-right: 10px;
              width: 82px;

              &:hover {
                color: $grey
              }
            }
          }

          .hostname {
            width: 250px;
          }

          .ipaddress {
            width: 250px;
          }

          .open_issues {
            width: 200px;
          }
        }
      }
    }
  }
}
