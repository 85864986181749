@import '../../variables';

.modal-container {
  position: fixed;
  width: 80%;
  left: 10%;
  top: 30%;
  z-index: 11;
  box-shadow: 0 0 0 50vmax rgba(0,0,0,.5);
  z-index: 101;
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #14373f;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.4);
  }

  .close-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
    border: none;

    .modal-close-icon {
      position: fixed;
      z-index: 999;
      cursor: pointer;
      background-color: #344c50;
      border: 1px solid #515c5d;
    }
  }

  .modal-data {
    border: 1px solid #586565;
    background-color: #1c4148;
    overflow-y: scroll;
    overscroll-behavior: contain;
    height: 50vh;
    margin: -2px -2px 0 0;

    table {
      border-collapse: collapse;
      display: table;
    }

    .modal-title {
      width: 100%;
      padding: 15px 20px 10px 30px;
      font-family: $roboto-bold;
      letter-spacing: -0.5px;
      font-size: 18px;
      border-bottom: 1px solid #4c6267;
      margin-bottom: 0;
    }

    .list-row {
      border-bottom: 1px solid #4c6267;

      .list-item {
        padding: 10px 30px 10px 30px;
        margin-top: -5px;
        font-family: $paragraph;
        color: #bccccd;

        &.status {
          padding-left: 15px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
