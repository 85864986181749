@import "../../../variables";

.child-tenant-container {
  font-family: $paragraph;
  .child-page-subtitle {
    padding: 0px;
    color: $grey;
  }
  .tenant-name-container {
    padding-bottom: 55px;
    .tenant-name {
      padding-top: 50px;
      .tenant-name-title {
        padding-right: 24px;
        color: $grey;
        font-size: 17px;
      }
      .input-tenant-name {
        width: 400px;
        &.input-error {
          box-sizing: border-box;
          border: 2px solid #FF441D;
        }
      }
    }
  }
  .services-container {
    .service-table {
      border-collapse: collapse;
      width: 1000px;
      .mdr-dropdown-container {
        padding-top: 20px;
        color: $darkgrey;
      }
      .mdr-service-row {
        border-bottom: none;
        padding-left: 15px;
        padding-bottom: 0px;
        padding-top: 0px;
      }
      .input-checkbox {
        height: unset;
      }
      .tr-headers {
        border-bottom: 1px solid #616B6B;
        .th-units-header {
          font-size: 16px;
          font-family: $roboto;
          color: $grey;
        }
      }
      .dropdowns-container {
        color: $darkgrey;
        &.unselected {
          pointer-events: none;
        }
        &.selected {
          pointer-events: all;
          color: $white;
        }
        &.log {
          display: flex;
        }
      }

      .td-units-column {
        position: relative;
        height: 35px;
        width: fit-content;
        .edr-offerrings {
          position: relative;
          top: 5px;
        }
        &.defender-dropdown {
          padding-top: 3px;
        }
        &.cs-dropdown {
          padding-bottom: 5px;
        }
        &.log-dropdown {
          left: 20px;
          width: 90px;
        }
        &.true {
          width: 90.3px;
          white-space: nowrap;
        }
        &.unselected {
          pointer-events: none;
        }
        &.selected {
          pointer-events: all;
          color: $white;
          &.service-error {
            color: #FF441D;
          }
        }
      }

      .dropdown-title {
        color: unset;
        font-size: 16px;
      }
      
      td {
        border-bottom: 1px solid #616B6B;
        padding-bottom: 10px;
        padding-top: 10px;
      }
      .service-selection {
        display: flex;
        .service-sub-text {
          padding-top: 5px;
          color: $darkgrey;
          padding-right: 10px;
        }
        .tooltip-icon {
          vertical-align: -webkit-baseline-middle;
        }
      }
      .mdr-tenant-tooltip {
        padding-left: 16px;
      }
      .dropdown-container {
        display: block;
        &.open {
          background-color: #14373f;
          border: 1px solid #616B6B;
          max-height: 240px;
          overflow-y: scroll;
          position: absolute;
          z-index: 100;
          padding-left: 10px;
          padding-top: 4px;
          width: max-content;
        }
        .list-container {
          .list-item {
            &:hover {
              font-weight: 500;
              color: $yellow;
            }
          }
        }
      }
    }
  }
  .add-btn-container {
    left: 56em;
    margin-top: 20px;
    .submit-message {
      height: 55px;
      .submit-error {
        position: relative;
        right: 10em;
        color: #FF441D;
        top: 7px;
      }
      .submit-success {
        padding-bottom: 10px;
        position: relative;
        right: 16em;
        color: $green;
        &.shown {
          opacity: 1;
          transition: all 250ms linear;
        }
        &.hidden {
          opacity: 0;
          height: 0;
          transition: all 250ms linear 2s;
        }
      }
    }
  }
}