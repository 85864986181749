@import '../../variables';

.vuln-pagination-container {
  .bulk-comment-container {
    width: 700px;
    height: 275px;
    left: 40%;
    box-shadow: 0 0 0 60vmax rgba(0, 0, 0, 0.5);

    .modal-data {
      height: 275px;
      padding: 20px;
      overflow: hidden;

      .input-label {
        font-family: $heading;
        font-weight: 500;
        border-bottom: 1px solid #586565;
        margin: -10px -20px 20px -20px;
        overflow: hidden;
        padding: 0 20px 10px 20px;
      }

      .input-box {
        color: black;
        font-family: $paragraph;

        &::placeholder {
          color: $darkgrey;
        }
      }

      .comment-text {
        color: #808a8a;
        white-space: nowrap;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-top: 10px;
        padding-right: 1px;

        .page-loading {
          &.relative-loader {
            margin-top: -12px;
          }
        }
      }
    }
  }

  .add-btn {
    cursor: pointer;
    color: $grey;
    border: #617b80 3px solid;
    text-align: center;
    width: 105px;
    height: 33px;
    font-size: 16px;
    line-height: 25px;
    margin: 0 0 7px 20px;
  }
}
