@import "../../variables";

.options-modal-container {
  .modal-header {
    margin-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid $darkgrey;
    width: 100%;
    font-family: $heading;
    color: $lightgrey;
  }

  .modal-container {
    width: 740px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .modal-data {
      height: fit-content;
      max-height: 450px;
      overflow: hidden;
    }
  }

  .modal-content {
    height: fit-content;
    width: 80%;
    margin: auto;
    .relative-loader{
      &.modal-loader{
        position: relative;
        margin: auto;
        height: 200px;
        left: 260px;
        top: 50px;
      }
    }
    .description {
      margin-top: 20px;
    }
    .radio-form {
      margin-top: 20px;
      .form-label {
        float: left;
        margin-right: 40px;
      }
      .options-container {
        margin-top: -5px;
        float: left;
        width: 110px;
        .label {
          min-width: 75px;
          float: right;
          margin-top: 5px;
        }
        .option{
          margin-bottom: 5px;
        }
        .custom-checkbox {
          svg {
            color: #677979;
            border-radius: 0px;
          }
        }
      }
      .radio-selection-description {
        font-size: 14px;
        margin-left: 50px;
        margin-top: -8px;
        margin-bottom: 10px;
      }
      .response-selection {
        margin-top: -5px;
      }
      .MuiListItemIcon-root {
        min-width: 0px;
        .MuiSvgIcon-root {
          fill: $grey;
        }
      }
      .MuiTypography-root {
        font-size: 16px;
      }
    }
    .footer {
      position: relative;
      clear: both;
      height: 70px;
      .submit-error {
        position: absolute;
        color: $red;
        right: 120px;
        top: 22px;
        .err-icon {
          position: relative;
          fill: $red;
          top: 2px;
          margin-right: 5px;
        }
      }
      .save-btn {
        position: relative;
        float: right;
        margin-top: 15px;
      }
      .relative-loader{
        position: relative;
        float: right;
        left: -20px;
        top: 5px;
      }
    }
    .success-content {
      position: relative;
      min-height: 200px;
      margin-top: -10px;
      .message-container {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        .message {
          letter-spacing: 0.1px;
          &.success {
            color: $yellow;
            font-family: $roboto;
          }
          &.note {
            margin-top: 15px;
            color: $grey;
            font-family: $paragraph;
          }
        }
      }
    }
  }
}
