@import '../../variables.scss';

.pagination-controls {
  margin: 30px 60px -20px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);

  .table-controls {
    justify-content: flex-end;
    margin-right: -87px;
    z-index: 11;
    display: flex;
    width: 350px;
    align-items: center;
  }

  .sort-by {
    position: relative;
    right: 0;
    width: 150px;
    margin-bottom: 15px;

    &.open {
      position: relative;
      z-index: 9;
      right: 0;
      background-color: #14373f;
      border: 1px solid #616B6B;
      border-radius: 2px;
      margin: 0 1px -60px 0;

      &.log-search {
        margin-bottom: -20px !important;
        margin: 0;
      }

      &.vulnerabilities {
        margin-bottom: -45px !important;
        right: 0px !important;
      }
    }
  }

  .closed-dropbox {
    cursor: pointer;
    width: 150px;
    display: flex;
    justify-content: space-between;
  }

  .pagination {
    display: flex;
    align-items: baseline;

    .general-tooltip {
      padding-left: 10px;
    }

    .expand-all {
      margin-left: 40px;
    }
  }

  .total-results {
    display: flex;
    align-items: baseline;
    margin-right: 20px;
    font-family: $paragraph;

    .total {
      margin-left: -10px;
      font-size: 25px;
      letter-spacing: -0.06px;
      margin-right: 10px;
    }
  }

  .showing-value {
    color: $white;
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    list-style: none;
    cursor: pointer;
    padding-left: 0;

    li {
      padding: 5px;
      color: $white;
      font-weight: 100;
    }

    .pagination-disabled {
      color: $darkgrey;
      cursor: auto;
    }

    li {
      color: $grey;
    }

    .pagination-active {
      color: $white;
      font-weight: bolder;
    }
  }
}
