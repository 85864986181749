@font-face {
  font-family: 'Barlow';
  src: local('Barlow'), url(../fonts/Barlow-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoCondensedLight';
  src: local('RobotoCondensedLight'), url(../fonts/RobotoCondensed-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoCondensed';
  src: local('RobotoCondensed'), url(../fonts/RobotoCondensed-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoCondensedBold';
  src: local('RobotoCondensedBold'), url(../fonts/RobotoCondensed-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'BarlowMedium';
  src: local('BarlowMedium'), url(../fonts/Barlow-Medium.ttf) format('truetype');
}

/* font-family*/
$heading: BarlowMedium, "Barlow Medium";
$quote: RobotoCondensedLight, "Roboto Condensed Light";
$paragraph: RobotoCondensedLight, "Roboto Condensed Light";
$roboto: RobotoCondensed, "Roboto Condensed";
$roboto-bold: RobotoCondensedBold, "Roboto Condensed Bold";
$barlow-bold: Barlow, "Barlow";
$subtitle: Barlow, "Barlow Regular";
$button: RobotoCondensedLight, "Roboto Condensed Light";

/* Primary Colors */
$black: #000000;
$yellow: #d0f31b;

/* Secondary Colors */
$white: #FAFBFB !default;
$lightgrey: #ECEFEF;
$grey: #C0D0D0;
$darkgrey: #616B6B;
$cyan: #018D8D;
$darkcyan: #002C34;
$lightcyan: #04515f;

/* Tertiary Colors */
$cyan-01: #35BEBE;
$green: #14DE42;
$yellow-01: #FFE133;
$orange: #FFA415;
$red: #D64526;
$darkturquoise: #094650;

/* Gradient */
$gradient: linear-gradient(#133f47, $darkcyan);;
