@import '../../variables';

.table-header-wrapper {
  box-shadow: inset 0 0.5px 0 rgb(46 82 89), inset 0 -0.5px 0 #616B6B;
  color: rgba(255, 255, 255, 0.6);
  height: 40px;
  background-color: #1e4148;
  font-family: $paragraph;
  text-align: left;
  position: sticky;
  top: 0;

  .header-item {
    font-weight: 300;
    padding-left: 30px;
  }
}
