@import "../../variables";

.graph-controls-container {
  display: flex;
  user-select: none;
  .slider text {
    fill: $white;
  }

  .slider-container {
    float: left;
    padding: 10px 20px;
    flex: 1;

    .slider {
      overflow: visible;
      z-index: 10;
      width: 100%;
      flex: 1;
    }
  }

  .slider-info {
    display: flex;
    margin-bottom: -15px;

    .slider-title {
      text-align: left;
      margin-left: 20px;
      margin-bottom: 0;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
