@import "../../../variables";

.tenants-onboarding-content {
  .add-tenant-button {
    font-size: 15px;
    font-family: $button;
    width: fit-content;
    cursor: pointer;
    padding-top: 30px;
    letter-spacing: normal;
    &.show {
      display: contents;
    }
    .add-tenant-icon {
      padding-left: 5px;
      font-size: 17px;
      &.show {
        padding-left: 0px;
        padding-right: 5px;
      }
    }
  }
  .tenant-row {
    height: 60px;
    .tenant-cell {
      color: $grey;
      margin-left: 3px;
      &.name {
        font-weight: 600;
        cursor: pointer;
        white-space: nowrap; 
        padding-right: 40px;
        &:hover {
          color: $yellow;
        }
      }
      &.onboardingStatus {
        &.Pending {
          color: $yellow-01;
        }
        &.Fulfilled {
          color: $green;
        }
        &.Completed {
          color: $green;
        }
      }
    }
  }
  .tenant-tooltip {
    position: absolute;
    right: 0px;
    top: 8px;
    .instructions-tooltip {
      width: 257px;
    }
    .tooltip-icon:hover {
      opacity: 0.9;
    }
  }
  .open-modal-btn {
    fill: $grey;
    cursor: pointer;
    &:hover {
      fill: $lightgrey;
    }
  }
  .tenants-close-icon {
    fill: $darkgrey;
    width: 25px;
    height: unset;
    margin-left: -5px;
    margin-top: 4px;
  }
  .tenants-checkmark {
    margin-bottom: 2px;   
  }
}
