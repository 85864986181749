@import "../../../variables";

.cloud-tab {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  margin-right: -1px;
  border-color: $darkgrey;

  &.selected {
    border-width: 0 1px 0 1px;
    background: rgb(124,146,152);
    background: linear-gradient(180deg, rgba(124,146,152,0.3) 14%, transparent 77%);
  }

  &.add-service {
    font-size: 12.5px;
    color: #c0cfd0;
    border-right: 0;
    flex-grow: 1;
    justify-content: flex-end;
  }

  &.okta {
    padding-right: 5px;
  }

  .tab-icons {
    width: 35px;
    height: 35px;
    margin-right: 10px;

    &.okta {
      margin: -40px -15px -35px -30px;
      width: 110px;
      height: 110px;
    }
  }

  .tab-close-icon{
    margin-left: 7px;
    margin-right: -10px;
    color: $lightgrey;
    &:hover{
      opacity: 0.8;
    }
  }

  &.close-visible {
    .tab-icons{
      &.okta{
        margin-right: -20px;
        margin-left: -25px;
        width: 95px;
      }
    }
    .tab-close-icon{
      &.okta {
        margin-right: 8px;
      }
    }
  }
}
