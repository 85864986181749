@import '../../variables';

.dropdown-container {

  .dropdown-title {
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;

    .caret-container {
      &.true {
        margin-left: auto;
      }
    }
    .caret {
      width: 15px;
      margin: 5px;
    }
  }

  &.dropdown-sort {
    margin: 10px;
  }

  .list-container {
    color: rgba(255, 255, 255, 0.75);

    .list-item {
      user-select: none;
      cursor: pointer;
      margin-bottom: 0;
      &:hover {
        color: $lightgrey;
      }
      &.selected {
        font-weight: bold;
      }
    }
  }
}

.dropdown-checkbox-container {
  margin: 10px;

  .dropdown-title {
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 5px;
    cursor: pointer;
    display: flex;
    align-items: baseline;

    .caret {
      width: 15px;
      margin-left: 5px;
    }
  }

  .checkbox-container {
    .checkbox {
      border-radius: 1px;
      border-color: rgba(255, 255, 255, 0.6);
      margin-right: 4px;
    }

    .checkbox-icon {
      width: 24px;
      background-color: rgba(255, 255, 255, 0.6);
      cursor: pointer;
    }

    label {
      font-size: 15px;
      cursor: pointer;
      margin-left: 5px;
    }
  }
}
