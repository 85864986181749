@import '../../variables';

.overview-container {
  min-height: 100vh;
  display: flex;
  background-image: linear-gradient(to bottom, #002c34, black);

  .left-panel {
    width: 50vw;
    padding-left: 10px;
    min-width: 840px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    .data-panel {
      display: flex;
      justify-content: space-between;
      padding: 0 50px;
      margin-top: 50px;

      .tooltip-container {
        &.overview {
          font-size: 40px;
          font-family: $barlow-bold;
          color: rgba(192, 208, 208, 0.7);
          white-space: nowrap;
          width: 140px;
          margin-right: 20%;
          height: 60px;

          .title-tooltip {
            width: 450px;
            white-space: break-spaces;
            left: 200px;
          }
        }
      }

      .value {
        font-size: 40px;
        font-family: $barlow-bold;
        color: rgba(192, 208, 208, 0.7);
        white-space: nowrap;
        width: 140px;
        margin-right: 20%;
      }

      .label {
        font-size: 15px;
        color: $white;
        display: inline-block;
        vertical-align: top;
        margin-top: 12px;
        padding-left: 10px;
        letter-spacing: 1.5px;
      }
    }
  }

  .right-panel {
    flex: 1;
    position: relative;
    background: linear-gradient(180deg, rgba(22, 60, 65, 1) 20%, rgba(6, 13, 15, 1) 77%);
    padding: 20px 0 50px 45px;
    overflow-y: auto;
    overflow-x: hidden;
    overscroll-behavior-y: contain;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient(180deg, rgba(33, 85, 92, 1) 20%, rgba(11, 23, 27, 1) 77%);
    }

    > * {
      overflow: hidden;
      flex-shrink: 0;
    }

    .vuln-graph-container {
      margin: 20px 0 10px;
      padding-top: 11px;

      .tooltip {
        left: calc(400% + 10px);
        width: 300px;
        white-space: break-spaces;
        top: 35px;
      }

      &.title {
        margin-bottom: 0;
      }
    }

    .newvuln-panel-tabs {
      margin: 25px 0 0 10px;
      height: 40px;
      position: relative;
      width: fit-content;
      display: flex;

      .tab {
        height: 25px;
        font-size: 14px;
        color: $grey;
        letter-spacing: 2px;
        font-family: $subtitle;
        text-transform: uppercase;

        &.selected {
          color: $lightgrey;
        }
      }

      .tooltip {
        margin-top: 30px;
      }
    }

    .bar-chart-container {
      .no-data {
        margin: 35px 0 0 10px;
      }

      text {
        color: #899797;
        fill: #899797;
        font-family: $paragraph;
      }
    }

    .data-list-container-detections {
      background-image: none;
      margin-left: -22px;
      display: flex;
      justify-content: flex-start;

      .table-container {
        width: 50%;
        margin-left: 20px;
      }

      > *:last-child {
        align-self: center;
      }
    }
  }
}
