@import '../../../variables';

.new-comment-modal {
  cursor: default;
  &.vulnerabilities {
    &.modal-container {
      z-index: 15;
      width: 400px;
      position: relative;
      top: -67px;
      margin-bottom: -254px;
      box-shadow: none;
      left: -280px;

      .close-button-container {
        display: none;
      }

      .modal-data {
        height: 255px;
        overflow: hidden;
        padding: 15px;
        width: 500px;

        .page-loading {
          &.relative-loader {
            position: absolute;
            left: 100px;
            top: 110px;
          }
        }

        .comment-close-icon {
          position: absolute;
          z-index: 999;
          cursor: pointer;
          background-color: #344c50;
          border: 1px solid #515c5d;
          right: -100px;
          top: -21px;
        }

        .modal-title {
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
          display: flex;
          justify-content: space-between;
          padding: 0 20px 0 15px;
          margin: -5px -15px;
          width: 494px;

          .edit-pencil {
            width: 17px;
            cursor: pointer;
            &:hover {
              opacity: 0.6;
            }
          }
        }

        .comment-text {
          font-family: $paragraph;
          color: rgba(255, 255, 255, 0.7);
          padding: 15px 0;
          height: 150px;
          overflow-y: auto;
          margin-top: 15px;
          margin-left: 2px;
          white-space: pre-wrap;
        }

        .add-comment-title {
          font-family: $heading;
          padding-bottom: 15px;
          font-weight: bold;
        }

        .add-comment-input {
          border-radius: 1px;
          color: $black;
          font-family: $paragraph;
          margin-top: 15px;

          &::placeholder {
            color: $grey;
          }
        }

        
      }
    }
  }
  .update-button-comment {
      margin-top: 15px;
      float: right;
      background-color: transparent;
      font-size: 17px;
      color: $white;
      font-size: 14px;
      color: $grey;
      border-color: $grey;
      position: relative;
      bottom: 5px;
      &:hover {
        color: $white;
        border-color: $white;
      }
  }
  .error-message{
    color: $red;
    position: relative;
    top: 90px;
    left: 110px;
  }
}
