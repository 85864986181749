.onboarding-container {
  .firewall-container {
    .firewall-title {
      float: none;
    }

    .table-column {
      cursor: pointer;
    }

    .edit-firewall {
      .modal-container {
        width: 900px;
      }

      .modal-data {
        height: 370px;
        padding-right: 50px;

        .edit-modal-content {
          padding-top: 0;
        }

        .firewall-form-title {
          margin-bottom: -10px;
        }

        .dropdown-label {
          width: 167px;
          margin-right: 0;
        }
      }
    }

    .modal-data {
      .relative-loader {
        left: 0;
      }
    }
  }
}
