.new-vuln-table {
  &.new-vuln {
    &.table-container {

      .td-value {
        width: unset;

        &.timestamp {
          width: 23.33%;
          margin-right: 0;
        }

        &.Time {
          margin-right: 0;
        }

        &.vulnerability_id {
          width: 12%;
        }

        &.severity {
          width: 9%;
        }

        &.description {
          width: 50%;

          .desc-value {
            margin-left: -100%;
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
            max-width: 100%;
            height: 70px;
            line-height: 70px;
            position: relative;
            z-index: 0;
          }

          .title-container {
            width: 100%;
            position: relative;
            height: 70px;
            z-index: 1;

            .tooltip-container {
              width: 100%;
              height: 70px;

              .tooltip {
                &.bottom {
                  top: calc(100% - 10px);
                }

                &.top {
                  position: absolute;
                }

                &.description-tooltip {
                  width: 600px;
                  white-space: break-spaces;
                  height: fit-content;
                }
              }
            }

            .text {
              overflow: hidden;
              max-width: 100%;
              display: block;
            }
          }
        }

        &.exploit {
          width: 6%;
        }

        &.cvss_score {
          width: 9%;
          padding-left: 35px;
        }

        &.asset_count {
          width: 5%;
        }
      }

      .table-row {
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }

        &.selected {
          background-color: #1b4249;
        }
      }
    }
  }
}
