@import '../../../variables';

.vulnerability-table-container {
  padding-top: 13px;
  white-space: nowrap;
  flex: 1;

  .see-all-btn {
    color: $darkgrey;
    font-size: 15px;
    padding-left: 3.5px;
    cursor: pointer;
    &:hover {
      color: $grey
    }
  }

  .no-data {
    margin-top: 25px;
  }

  .header {
    margin-bottom: -15px;
    margin-left: 2px;
    font-family: $heading;
    font-size: 14px;
    letter-spacing: 1.5px;
  }

  .table-container {
    width: 100%;
    font-size: 14px;

    .table-headers {
      th{
        padding-top: 5px;
      }
      .hidden {
        visibility: hidden;
      }

      .assets {
        position: relative;
        left: 262px;
      }

      .count {
        padding-left: 315px;
        padding-top: 6px;
      }

      .cvss_score {
        position: relative;
        left: 245px;
      }
    }

    tbody {
      tr {
        cursor: pointer;
        td {
          p {
            margin-bottom: 0;
          }
        }

        &:hover{
          .description, .host, .cve{
            color: $yellow;
          }
          .cvss_score, .assets, .count{
            color: $grey;
          }
        }

        .assets {
          padding: 0 85px 0 10px;
        }

        .host {
          width: 344px;
          padding-bottom: 1px;
          color: $grey;
          &:hover {
            color: $yellow;
          }
        }

        .cvss_score {
          width: 75px;
        }

        .cve{
          width: 120px;
        }

        .description {
          width: 220px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 25px;
          color: $grey;
        }

        .title-container {
          .tooltip {
            width: 500px;
            white-space: break-spaces;
          }
        }
      }
    }

    tr:hover td {
      color: $yellow;
    }

    th:nth-last-child(-n + 2) {
      color: #707D7D;
    }

    tr td:nth-last-child(-n + 2) {
      color: #707D7D;
    }
  }
}
