@import '../../variables';

.hosts-container {
    .header-row {
        display: flex;
        justify-content: space-between;
        padding-right: 50px;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    .hosts-tabs {
        padding-top: 50px;
        .tab-nav {
            display: flex;
            align-items: flex-end;
            .hr-line {
                background-color: #ffffff33;
                height: 1px;
                &.first {
                    padding-right: 2.9em;
                }
                &.second {
                    flex: 1 1 0%;
                }
            }
            .tab-container {
                .tab-button {
                    background-color: transparent;
                    font-size: 15px;
                    color: #C0D0D0;
                    letter-spacing: 1.76px;
                    cursor: pointer;
                    border-left: 1px solid rgba(255, 255, 255, 0.2);
                    border-top: none;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                    border-right: none;
                    padding-bottom: 3px;
                    font-family: $subtitle;
                    padding-right: 15px;
                    padding-left: 15px;
                    &.active {
                        border-bottom: none;
                        color: #FFFFFF;
                        background-image: linear-gradient(180deg, rgba(28,65,72,0.85) 0%, rgba(28,65,72,0.01) 100%);
                    }
                    &.border {
                        border-right: 1px solid rgba(255, 255, 255, 0.2);
                    }
                    .tab-value {
                        font-size: 22px;
                        font-family: $heading;
                    }
                    &.right{
                        border-right: 1px solid rgba(255, 255, 255, 0.2);
                    }
                }
            }
        }
    }
}

.hosts-filters {
    border-top: none;
    padding-top: 1%;
    .dropdown-checkbox-title {
        color: rgba(255, 255, 255, 0.6);
    }
}