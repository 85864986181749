@import "../../../variables";

.ip-address-input {
  .input-label {
    color: $grey;
    float: left;
    margin-right: 28px;
    margin-top: 32px;
    font-family: $paragraph;

    .subheader-info {
      color: #7f9698;
    }
  }
  .ip-tooltip {
    padding-left: 10px;
    position: relative;
    top: 30px;
    right: 10px;
    cursor: pointer;
  }

  .input-right {
    margin-left: 45px;
    float: left;
  }

  .input-instructions {
    font-size: 14px;
    color: #7f9698;
    margin-bottom: 8px;

    &.scan-time {
      float: left;
      margin-top: 35px;
      margin-left: 17px;

      &.timezone {
        margin-top: 0px;
        margin-left: 0;
        position: relative;
        right: 7em;
      }
    }

    &.scan-day {
      float: left;
      margin-top: 2px;
    }

    &.is-error {
      color: $red;
    }
  }

  .ip-input {
    height: 250px;
    width: 550px;
    color: black;
    font-family: $paragraph;
    line-height: 20px;
  }

  .ip-input::placeholder {
    color: $grey;
  }

  .submit-error {
    color: $red;
    margin-top: 3px;

    &.edit {
      position: relative;
      right: 36.5em;
      bottom: 34px;
      width: 28em;
    }
  }

  .err-icon {
    position: relative;
    top: 2px;
    color: $red;
    margin-right: 3px;
  }

  .scan-time-container {
    position: fixed;
    left: 20px;
    top: 330px;

    &.false {
      z-index: 100;
    }

    .dropdown-container {
      float: left;
      min-width: 84px;
      position: relative;
      left: 8.8em;
      border: 1px #C0D0D0 solid;
      margin-top: 28px;
      padding-left: 11px;
      background-color: #094650;
      height: 41px;
      padding-top: 6px;
      bottom: 1px;

      &.open {
        height: unset;
      }

      &.autocomplete {
        border: none;
        padding-left: 0px;
        left: 11em;
        padding-top: 0px;
      }

      .list-item {
        background-color: unset;
        padding-left: 0;
      }
    }

    .time-picker-container {
      top: 20px;
      left: 124px;
    }
  }

  .timezone {
    position: fixed;
    left: 22em;
    top: 310px;
    min-width: 60px;
    border: 1px #C0D0D0 solid;
    margin-top: 28px;
    padding-left: 11px;
    padding-top: 4px;
    background-color: #094650;

    .list-item {
      background-color: unset;
      padding-left: 0;
    }
  }

  .scan-day-container {
    position: fixed;
    left: 20px;
    top: 275px;
    z-index: 100;

    .dropdown-container {
      float: left;
      min-width: 60px;
      margin-left: 92px;
      margin-right: 15px;
      border: 1px $grey solid;
      margin-top: -4px;
      padding-left: 10px;
      padding-top: 4px;
      background-color: $darkturquoise;
    }

    .input-label {
      margin-top: 2px;
    }

    .list-item {
      background-color: $darkturquoise;
      padding-left: 0;
    }
  }
}
