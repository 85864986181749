@import "../../../variables";

.contacts-onboarding-content {
  position: relative;
  min-width: 1200px;

  .child-tenants-dropdown {
    vertical-align: middle;
    display: flex;
  }

  .form-container {
    position: relative;
    width: 100%;
  }

  .input-container {
    position: relative;
    clear: both;
    min-width: 1026px;
    width: 85%;
    padding-top: 20px;
    margin-bottom: 100px;

    .label {
      font-size: 15px;
      margin-bottom: 4px;
    }

    .firstname {
      width: 13.7%;
    }

    .lastname {
      width: 15%;
    }

    .contact {
      width: 28.5%;
    }

    .mobilePhone {
      width: 18.5%;
      display: inline-flex;
      vertical-align: middle;
      margin-bottom: 2px;
    }

    .jobTitle {
      min-width: 19%;
      width: 196px;
    }

    input::placeholder {
      color: $darkgrey;
    }

    .required {
      input::placeholder {
        color: $red;
      }
    }
  }

  .error {
    &.action {
      position: absolute;
      top: 204px;
    }
    &.edit {
      margin-top: 55px;
    }
  }

  td {
    white-space: nowrap;
  }

  .access-row {
    td {
      padding-top: 7px;
    }
  }

  .add-btn-container {
    right: -7px;
    bottom: 10px;
    height: 27px;
    .add-btn:hover {
      color: $lightgrey;
      border-color: $lightgrey;
    }
  }

  .actions-container {
    .options {
      width: 125px;
    }
  }

  .edit-modal {
    .modal-container {
      width: 750px;
      position: fixed;
      left: 49%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .edit-modal-content {
      position: relative;
      font-family: $paragraph;
      width: 670px;
      margin-left: 50px;
      top: 10px;

      .mobilePhone {
        width: 25%;
        display: inline-flex;
        vertical-align: middle;
        margin-bottom: 2px;
      }

      input::placeholder {
        color: $darkgrey;
      }

      .required {
        input::placeholder {
          color: $red;
        }
      }
    }

    .edit-header {
      position: absolute;
      font-family: $heading;
      color: $lightgrey;
      padding: 10px;
      padding-left: 15px;
      border-bottom: 1px $darkgrey solid;
      width: 100%;
    }

    .modal-data {
      height: 300px;
      overflow: hidden;
    }

    .edit-form-container {
      position: relative;
      top: 55px;
    }

    .submit-btn {
      position: relative;
      float: right;
      top: 130px;
      left: -19px;
      z-index: 1;
    }

    .relative-loader {
      position: relative;
      left: 250px;
      top: 110px;
    }
  }

  .add-user-checkboxes {
    position: relative;
    margin-top: 10px;
    float: right;
    margin-right: 0px;
    color: $lightgrey;
    z-index: 5;
    left: 20px;
    &.editing {
      margin-left: -20px;
      width: 100%;
      float: left;
    }

    .role-radio {
      float: left;
      margin-top: 7px;
      margin-right: 10px;
      cursor: pointer;

      .radio-container {
        position: relative;
        top: 3px;
        margin-left: 4px;
      }
    }

    .selections-container {
      float: left;
      height: 37px;
      left: 40px;
      margin-right: 50px;
      z-index: 10;

      cursor: pointer;

      &.selected {
        border-bottom: none;
      }

      .selections-label {
        font-size: 16px;
        position: relative;
        font-family: $paragraph;
        color: $grey;
      }

      .checkbox-options {
        list-style-type: none;
        min-width: calc(100% + 4px);
        position: relative;
        padding: 0px;
        padding-top: 0px;

        li {
          margin: 0px;
        }

        .checkbox-option {
          color: $lightgrey;
          position: relative;
          cursor: pointer;
          height: 28px;
          line-height: 28px;

          &.disabled{
            .checkbox-icon, .label{
              opacity: 0.5;
            }
            &:active{
              pointer-events: none;
            }
            .tooltip-container {
              .tooltip {
                width: 180px;
              }
            }
          }

          &:hover {
            color: $white;
          }

          &.selected {
            color: $white;
          }

          .tooltip-container {
            .tooltip {
              width: 250px;
            }
          }
        }
      }

      .checkbox-icon {
        position: relative;
        top: 3px;
        float: left;
        margin-right: 5px;
        fill: $darkcyan;

        svg {
          color: $red;
        }
      }

      .radio {
        position: relative;
        bottom: 0.5px;
      }
    }
  }

  .text {
    text-overflow: ellipsis;
    width: 87%;
    max-width: fit-content;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    &.status {
      margin-left: 3px;
    }
    &.access {
      &.true {
        overflow: visible;
        margin: 0;
      }
      .access-toggle-icon {
        width: 40px;
        cursor: pointer;
      }

    }
    &.contained {
      max-width: 100%;
    }
  }
}
