@import "../../../variables";

.cloud-onboarding-table {
  border-spacing: 0;
  width: 75%;
  margin-bottom: 50px;

  .cloud-table-row {
    height: 50px;
    background-color: $darkcyan;

    .cloud-table-cell {
      border: 1px solid $darkgrey;
      border-bottom: 0;
      color: $grey;
      padding-left: 20px;
      padding-right: 15px;
    }
  }

  .cloud-table-row:last-child {
    .cloud-table-cell {
      border-bottom: 1px solid $darkgrey;
    }
  }

  .no-accounts-text {
    display: none;
  }
}
