$control: 20%;
$category: 10%;
$tool: 10%;
$name: 27%;
$difficulty: 9%;
$status: 12%;


$expanded-control: 23%;
$expanded-category: 10%;
$expanded-tool: 10%;
$expanded-name: 20%;
$expanded-difficulty: 10%;
$expanded-status: 15%;