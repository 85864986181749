@import '../../variables';
@import './HygieneVariables';

.hygiene-page-container {
  .loader-container {
    height: 85vh;
  }

  .table-header-container {
    .td-value {
      &.report_date {
        display: flex;
        width: 11.5%;
        margin-left: -5px;
        margin-right: 20px;
      }

      &.Service {
        width: $service;
      }

      &.Severity {
        width: 10.5%;
        margin-right: 6px;
      }

      &.resource_id {
        width: $resource_id;
      }

      &.compliance_check {
        width: 30%;
      }

      &.Status {
        width: 7%;
      }

      &.account_id {
        width: $account;
      }

      &.Region {
        width: 7%;
      }
    }
  }

  .no-results-text {
    display: flex;
    justify-content: left;
    margin-top: 2%;
    margin-left: 2%;
    font-size: 18px;
    color: #C0D0D0;
  }

  .header-row {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    .page-title {
      width: 200px;
      white-space: nowrap;
    }
  }

  .loader-container {
    height: 31em;
    display: flex;
    justify-content: center;
  }

  .top-container {
    display: flex;
    max-height: fit-content;
    height: 31em;

    &.true {
      height: 4.8em;
      overflow: hidden;
      cursor: pointer;
    }

    &.no-graph-data {
      height: 24em;
    }

    .graph-container {
      align-items: center;
      padding-bottom: 2em;
      width: 73%;

      &.right {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0)) 1 100%;

        &.no-data {
          width: 38em;
        }
      }

      &.no-data {
        width: 38em;
      }

      .legend-container {
        overflow: visible;
        display: flex;
        position: relative;
        left: 3.4em;
        bottom: 29px;
        padding-bottom: 2.3em;

        &.right {
          top: 14px;
          bottom: 0;
          left: 3em;
        }
      }

      .top-issues-by-failure-container {
        margin-left: 1.5em;
        display: inline-flex;
      }

      .top-remediations-container {
        margin-left: 1em;
        display: inline-flex;
      }

      .no-data {
        text-align: center;
        font-size: 36px;

        &.right {
          padding-top: 1.14em;
        }
      }

      .title-and-legend {
        display: flex;
        margin-top: 30px;

        &.right {
          margin-top: 26px;
        }

        &.true {
          padding-bottom: 3em;
        }

        .expand-btn {
          padding-left: 34px;
          font-size: 39px;
          position: relative;
          bottom: 21px;
          color: #C0D0D0;
          cursor: pointer;

          &.true {
            font-size: 21px;
            bottom: 5px;
            background-image: linear-gradient(#C0D0D0, transparent);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .graph-title {
          white-space: nowrap;
          font-weight: 450;
          font-family: $heading;
          margin: 0 -200px -10px 10px;
          letter-spacing: 1.5px;
          position: relative;
          bottom: 5px;

          &.right {
            padding-left: 34px;
            padding-bottom: 5px;
            bottom: 1px;
          }

          &.true {
            background-image: linear-gradient(#C0D0D0, transparent);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        svg {
          margin-top: -5px;
        }
      }

      .severity-line-chart {
        float: left;
      }

      .trend-graph {
        margin-top: -55px;
        margin-left: 25px;

        &.right {
          margin-top: -11px;
        }
      }
    }
  }
  .generic-checkbox-label{
    &.PASS,&.FAIL,&.INFO,&.WARNING{
      text-transform: lowercase;
    }
  }
  .generic-checkbox-label:first-letter{
    text-transform: capitalize;
  }
}

.line-filter {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2%;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
}

.table-row {

  &.expanded {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .Severity {
    width: 11%;
    margin-right: 0;
    margin-top: 20px;
    display: flex;
    align-items: center;

    .severity-container {
      width: fit-content;
      margin-top: 2px;
      margin-bottom: 20px;
    }
  }

  .td-value {
    display: flex;
    align-items: center;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.8);
    white-space: nowrap;
    margin-right: 20px;

    .tooltip-value {
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      white-space: nowrap;

      &.resource_id {
        max-width: 170px;
      }

      &.compliance_check {
        max-width: 340px;
      }

      &.service {
        max-width: 85px;
      }

      &.status {
        padding-top: 2px;
        height: 33px;
        &.FAIL{
          position: relative;
          left: -5px;
        }
      }
    }

    .expanded {
      display: flex;
      align-items: center;
      white-space: pre-line;
      overflow-wrap: break-word;
    }

    &.pass {
      color: $yellow;
    }

    &.fail {
      color: #FFA415;
    }

    &.info {
      color: $grey;
    }

    &.warning {
      color: #FFE133;
    }

    .expand-btn {
      cursor: pointer;
      position: absolute;
      z-index: 5;
      margin-left: -25px;
      margin-right: 8px;

      &.minus {
        font-size: 30px;
        margin-top: 0;
        margin-right: 8px;
      }
    }

    &.report_date {
      display: flex;
      width: 11.8%;
      margin-left: -4px;
      margin-right: 8px;
    }

    &.service {
      width: $service;
      padding-left: 9px;
    }

    &.resource_id {
      width: $resource_id;
      padding-left: 9px;
      overflow: unset;
      word-break: break-all;
    }

    &.compliance_check {
      width: 29%;
      margin-right: 3em;
      padding-left: 10px;
      overflow: unset;
    }

    &.status {
      width: 6%;
      margin-right: 2.9em;
      padding-left: 2.5px;
    }

    &.account_id {
      width: $account;
    }

    &.region {
      width: 7%;
      padding-left: 5px;
    }
  }
}

.hygiene {
  &.true {
    &.table-row {
      padding-bottom: 2px;
      padding-top: 2px;
      height: unset;
    }
  }
}

@media (max-width: 1680px) {
  body {
    overflow: scroll;
  }

  .hygiene-page-container {
    width: 1680px;
  }
}