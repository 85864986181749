@import "../../../variables";

.vuln-scan-modal {
  &.edit {
    .modal-container {
      width: 750px;
    }
    .modal-data {
      height: 335px;
      max-height: 520px;
      overflow-x: hidden;
      min-height: 505px;
    }
  }
  .modal-container {
    width: 550px;
    position: fixed;
    left: 49%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .modal-header {
    padding-left: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid $darkgrey;
    width: 100%;
    font-weight: 600;
    position: relative;
    margin-top: 10px;
  }
  .modal-data {
    height: fit-content;
    max-height: 400px;
    padding-bottom: 10px;
    overflow-y: auto;
  }
  .modal-content {
    position: relative;
    width: 100%;
    margin-top: 20px;
    padding-left: 20px;
    .subheader {
      float: left;
      color: $grey;
    }
    .ip-list {
      float: left;
      ul {
        list-style-type: none;
        li {
          margin-bottom: 2px;
        }
      }
    }
  }
  .edit-btn {
    position: fixed;
    float: right;
    right: 30px;
    top: 48%;
    &:hover {
      opacity: 0.8;
    }
  }
}
