@import "../../variables";

.file-storage-container {
  background-color: linear-gradient(to bottom, #0f3137, black);
  .header-row {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 50px;
  }

  .page-content {
    position: relative;
    width: 90%;
    max-width: 1400px;
    margin: auto;

    .root-title {
      font-family: $heading;
      letter-spacing: 2px;
      font-size: 20px;
      color: #d8d8d8;
    }

    .files-button {
      color: #c0d0d0;
      border: #617b80 3px solid;
      text-align: center;
      width: 110px;
      height: 30px;
      font-size: 16px;
      line-height: 25px;
      &:hover{
        color: $lightgrey;
        border-color: $lightgrey;
      }
    }

    .upload-btn-container {
      padding: 0px;
    }

    .folder-path {
      padding-top: 25px;
    }

    .sort-container {
      position: absolute;
      right: 0px;
      top: 60px;
      color: #c0d0d0;
      width: 140px;

      .label {
        padding-right: 8px;
      }

      .selection {
        font-weight: bold;
        cursor: pointer;
        .down-arrow {
          position: relative;
          color: #c0d0d0;
          top: 2px;
        }
        .down-arrow:hover {
          color: $white;
        }
      }

      .selection:hover {
        color: $white;
        .down-arrow {
          color: $white;
        }
      }

      .options {
        position: relative;
        left: 50px;
        top: 2px;
        background-color: $darkcyan;
        z-index: 10;
        list-style: none;
        width: 80px;
        border: 1px solid $darkgrey;

        .option {
          cursor: pointer;
          position: relative;
          right: 40px;
          line-height: 30px;
          vertical-align: middle;
          margin: auto;
          width: 80px;
          padding-left: 10px;
        }

        .option:hover {
          color: $white;
        }
      }
    }


        .clickable {
          display: flex;
          width: max-content;
          cursor: pointer;

        &.active {
          color: #c0d0d0;
        }
        }


        .sort-icon {
          position: relative;
          bottom: 1px;
          left: 4px;
        }
      

        .file-icon-cell {
          position: relative;
        }

        .file-icon {
          position: absolute;
          right: 9px;
          top: 0px;
        }
        .icon-container {
          position: relative;
          padding-left: 30px;
          .dl-icon {
            margin-right: 7px;
          }
          .download-btn{
            width: 17px;
          }
          .delete-btn {
            width: 12px;
          }
        }
        .confirm-cell {
          height: 5px;
          width: 200px;
          color: #c0d0d0;
          .confirm {
            padding-right: 0px;
            :hover {
              color: $white;
            }
          }
          .cancel {
            padding-left: 5px;
            :hover {
              color: $white;
            }
          }
          .color {
            color: #c0d0d0;
          }
        }
      }

      .table-column {
        &.icon {
          width: 5%;
        }

        &.name {
          width: 25%;
        }

        &.date {
          width: 15%;
        }

        &.creator_email {
          width: 15%;
        }

        &.tags {
          width: 20%;
        }

        &.buttons {
          width: 8%;
        }
      }

    .date-minutes {
      color: #849494;
      padding-left: 5px;
    }

    .tag-filter-container {
      position: absolute;
      right: 0px;
      top: 57px;
    }

    .tag-filter {
      cursor: pointer;
      align-items: center;
      margin: 3px;
      margin-right: 5px;
      padding: 5px 7px 5px 7px;
      border: 1px solid $lightcyan;
      border-radius: 5px;
      background-color: $lightcyan;
      white-space: nowrap;
      color: white;
      font-size: 15px;

      button {
        position: relative;
        bottom: 1px;
        padding: 6px;
        padding-right: 0px;
        border: none;
        background-color: unset;
        cursor: pointer;
        color: white;
      }
    }
    .report-button-container{
      position: relative;
      bottom: 22px;
      float: right;
      height: 20px;
      .loader-btn{
        width: 150px;
      }
      .error-msg-container{
        color: $red;
        font-size: 15px;
        .err-icon{
          position: relative;
          fill: $red;
          margin-right: 3px;
          top: 2px;
        }
      }
      .success-msg-container{
        color: $lightgrey;
        font-size: 15px;
      }
      .relative-loader{
        position: relative;
        margin-right: 50px;
        bottom: 12px;
      }
    }
  }

