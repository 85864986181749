@import "../../../variables";

.no-cloud-container {
  display: flex;
  flex-direction: column;
  color: $grey;

  .add-service-modal {
    position: relative;
    width: 200px;
    left: 0;
    top: 100px;
    margin-top: -100px;
    margin-bottom: -60px;
    margin-left: -5px;
  }

  .cloud-tab {
    &.add-service {
      cursor: auto;
      justify-content: flex-start;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      margin: 0 0 40px -75px;
      padding: 0 0 30px 70px;
      border-left: 0;
      font-size: 16px;
      width: 111.9%;

      span {
        cursor: pointer;
      }
    }
  }

  .no-cloud-tabs-container {
    display: flex;
    margin-left: -20px;

    .cloud-tab {
      border-style: none;

      .tab-icons {
        width: 50px;
        height: 50px;

        &.okta {
          margin: 0 -10px;
          width: 130px;
          height: 150px;
        }
      }

      .tab-text {
        display: none
      }
    }
  }
}
