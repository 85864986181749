@import '../../variables';

.maturity-assessment {
  &.pagination-controls {
    .total-results {
      margin-bottom: -35px;
      padding-left: 10px;
      min-width: 250px;

      .pagination {
        margin-left: 30px;
      }
    }

    #form-search-bar {
      border-radius: 0;
      margin: 0 0 0 50%;
    }

    #search-field {
      color: $grey
    }

    .MuiInputBase-root {
      background-color: white;
      border-radius: 0 !important;
      height: 27px;
    }

    .MuiInputBase-input {
      font-size: 13px;
    }

    .MuiFormLabel-root {
      font-size: 13px;
      margin-top: -4px;

      .input-label {
        font-size: 10px;
      }
      &.Mui-focused {
        color: transparent !important;
      }
    }

    fieldset {
      border-color:rgba(255, 255, 255, 0.6) !important;
    }

    .export-bau {
      margin: 0 -40px -40px 0;
    }
  }
}
