@import '../../variables';

.dashboard-container {
  display: flex;
  background-color: #0f3137;
  max-height: 100vh;
  overscroll-behavior: contain;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #14373f;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.4);
  }

  .table-row-container {
    cursor: default;
  }

  .line-filter {
    display: flex;
    justify-content: space-evenly;
    margin-top: 1%;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
  }

  .no-results-text {
    display: flex;
    justify-content: left;
    margin-top: 2%;
    margin-left: 45px;
    font-size: 18px;
    color: #C0D0D0;
  }

  .left-dashboard{
    background: linear-gradient(to bottom, #0f3137, black);
    min-width: 840px;
    background-color: #0e3036;

    .dashboard-table-container {
       background-color: #0f3137;
    }
  }

  .dashboard-controls {
    padding-left: 15px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 25px;
    height: 50px;
    padding-right: 22px;

    .label {
      letter-spacing: 2px;
      margin-left: 25px;
      font-family: $heading;
      font-weight: bold;

      .result-count {
        font-weight: 350;
        padding-left: 10px;
        font-size: 20px;
      }
    }

    .sort-by {
      &.open {
        background-color: #14373f;
        border: 1px solid #616B6B;
        z-index: 4;
        margin: -1px -2.5px 0 0;
      }
    }
  }

  .dashboard-info {
    display: flex;

    .critical-threats {
      font-family: $paragraph;
      position: relative;
      left: 95px;
      top: 5px;
      color: $red;
      font-size: 15px;
      letter-spacing: 0.25px;
      cursor: pointer;

      b {
        letter-spacing: 0.75px;
        font-family: $paragraph;
        font-weight: bold;
        display: inline-block;
        transform: scale(1, 1.2);
      }
      
      :hover{
        color: $yellow;
      }
    }
  }

  .dashboard-table-container {
    overflow-y: scroll;
    height: calc(100vh - 340px);
    overscroll-behavior: contain;
    overflow-x: hidden;
    min-width: 840px;
    width: 50vw;
    display: block;

    td {
      margin-right: 30px;
    }

    .dashboard {
      .table-row {
        padding: 0 50px 0 30px;
        justify-content: space-between;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }

        .left-row {
          min-width: 20%;
        }

        .severity-col {
          min-width: 10%;
          margin-top: 20px;
        }

        .source {
          color: #7D9395;
        }

        .title-target-col{
          min-width: 43%;
          max-width: 43%;

          .value {
            margin-left: 0;
            width: 100%;
            color: #7D9395;

            &.top {
              color: rgba(255, 255, 255, 0.8);
            }
          }
        }

        .status-bar-col{
          min-width: 20%;
        }
      }
    }
  }

  .right-dashboard {
    width: 50%;
    left: 50%;
    border-left: 1px solid #3a5054;
    overflow-y: scroll;
    overflow-x: hidden;
    overscroll-behavior: contain;
    height: 100vh;
    background: linear-gradient(to bottom, #0f3137, black);

    .critical-threats-list {
      background-size: contain;
      padding-left: 158px;
      padding-top: 52px;
      align-items: center;
      padding-bottom: 100px;
      height: 350px;
      font-family: $heading
    }

    .critical-threats-list-title {
      font-size: 18px !important;
      letter-spacing: 3px;
      font-family: $heading;
      color: rgba(255, 255, 255, 0.9);
      padding-bottom: 10px;
    }

    .critical-threat-list-items {
      font-size: 16px !important;
      color: rgba(255, 255, 255, 0.6);
      padding: 2px;
      font-family: $paragraph;
      letter-spacing: 2px;
    }

    .clickable-link {
      cursor: pointer;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;

      &:hover {
        color: $yellow;
      }
    }

    .right-item {
      border-bottom: 1px solid #3a5054;
      background-size: contain;
      .placeholder-image {
        width: 85%;
        margin-left: 10%;
      }
      &.critical-threats-list{
        border-bottom: 0;
      }
    }
  }

  .placeholder-image {
    width: 100%;
  }
}

@media (max-width: 1680px) {
  body {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .dashboard-container {
    width: 1680px;

    .right-dashboard {
      width: 840px;
      overscroll-behavior: auto;
    }
  }
}

@media (min-width: 2200px) {

  .dashboard-container {
    .right-dashboard {
      .right-item {
        .placeholder-image {
          width: 65%;
          margin-left: 20%;
        }
      }
    }
  }
}
