@import '../../variables';

.cloud-card-row {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  .cloud-card-container {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding: 30px 90px 30px 62px;
    display: flex;
    justify-content: center;
    font-family: $paragraph;

    &.clickable {
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    &:first-child {
      width: 25vw;
      padding-left: 100px;

      .cloud-type {
        margin-right: 0;
        margin-left: -20px;
      }
    }

    &:not(:first-child) {
      padding: 30px 50px 55px;
      flex-grow: 1;
    }

    .cloud-type {
      margin-left: 20%;
      margin-right: 20px;

      &.disabled {
        opacity: 0.4;
      }
    }

    .card-data {
      flex-grow: 1;
    }

    .connected {
      display: flex;
      margin-bottom: 0;

      .connected-text {
        margin-left: 10px;

        &.red {
          color: $red;
          font-family: $paragraph;
          letter-spacing: -0.5px;
          transform: scale(0.9, 1);
          margin-left: 5px;
        }
      }
    }

    .circle {
      display: flex;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      margin-left: 15px;
      margin-top: 6px;
      background-color: #768181;

      &.green {
        background-color: #99E100;
      }

      &.red {
        background-color: $red;
      }
    }

    .accounts {
      color: #768181;
      margin-left: 35px;
      height: 19px;
      cursor: pointer;
      width: fit-content;
      &:hover {
        color: $grey;
      }
    }

    .display-container {
      margin-bottom: -35px;
      margin-top: 15px;
      height: 80px;

      .display-values {
        display: flex;
        font-size: 15px;
        margin-bottom: -15px;

        .name {
          text-align: right;
          width: 98px;
          color: #768181;
          margin-right: 10px;
          height: 22px;
        }

        .value {
          text-align: left;
          color: $grey;
          white-space: nowrap;
          &.link {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (max-width: 1680px) {
  .cloud-card-row {
    min-width: 1680px;

    .cloud-card-container {
      min-width: 419px;
    }
  }
}
