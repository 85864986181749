.tree-component-container {
    .tree-container {
        padding-left: 40px;
        padding-top: 15px;
       .targetTree{
           color: #F36D10;
           .icon {
            width: 50px;
            display: flex;
             .expand{
               font-size: 30px;
             }
             .collapse{
               font-size: 30px;
             }
             .tree-icon{
                width: 30px;
             }
             &.end-target{
               width: 30px;
             }
           }
        }
    }

    .line-filter {
        display: flex;
        justify-content: space-evenly;
        margin-top: 2%;
        margin-bottom: 1px;
        border-top: 1px solid #616B6B;
    }
    
    .table-header-row {
        margin-left: 22px !important;
        font-size: 16.6px;
        color: white;
        font-weight: bold;
        white-space: nowrap;
        line-height: 30px;
        display: inline-block;
    }
    
    .table-body {
        line-height: 2em;
    }
    
    .process-details-container {
        padding-left: 22px;
        max-width: 635px;
        word-break: break-word;
        padding-top: 15px;
    }
    
    .detail-item-title {
        vertical-align: top;
        color: rgba(255, 255, 255, 0.4) !important;
        line-height: 25px !important;
        text-align: right;
        padding-right: 20px;
        width: 150px;
    }
    .detail-item {
        line-height: 22px !important;
    }
    .MuiTreeView-root {
        max-height: 445px;
        min-height: 250px;
        width: 100%;
        .MuiTreeItem-content {
            padding: 5px 8px !important;
            word-wrap: break-word;
            &.MuiTreeItem-label {
                font-size: 1.2rem;
                line-height: 2;
            }
            &.Mui-selected {
                background-color: #133f47 !important;
                width: fit-content !important;
            }
            .MuiTreeItem-iconContainer{
               width: 50px;
               display: flex;
               .icon {
                   display: flex;
                   .expand{
                      font-size: 20px;
                      margin-right: 10px;
                      font-weight: 500;
                   }
                   .collapse{
                      font-size: 20px;
                      margin-right: 10px;
                      font-weight: 500;
                   }
               }
               .tree-icon{
                  width: 30px;
               }
            }
        }
    }
}