@import '../../variables';

.vulnerabilities-container {
  padding-top: 30px;
  overflow-y: hidden;

  .beta-icon {
    width: 50px;
  }

  .vulnerability-container {
    padding-bottom: 20px;

    &.new-page {
      overflow-x: hidden;
      min-height: 80vh;

      &::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }
    }

    .flex {
      &.no-results {
        .pagination {
          margin-bottom: 20px;
        }
      }
    }

    .vulnerabilities-table {
      &.new {
        &.pagination-controls {
          width: 79.2%;
        }
      }
    }

    .export {
      width: 100px;
      position: relative;
      bottom: -53px;
      margin-right: 9vw;

      .export-container {
        justify-content: flex-end;

        .export-menu {
          z-index: 10;
        }
      }
    }
  }

  .vulnerabilities-filters-new {
    &.page-filters-container {
      padding-top: 20px;

      &.true {
        height: 77px;
        overflow: hidden;
        cursor: pointer;

        .tooltip-container {
          position: static;

          .tooltip {
            visibility: hidden;
          }

        }
      }

      .page-filters {
        padding-right: 80px;
        justify-content: space-between;
        flex: 1;
        max-width: 1000px;

        .STATUS {
          text-transform: capitalize;
        }

        .tooltip {
          &.filter-tooltip {
            text-transform: none;
          }
        }

        .time-dropdown-container {
          visibility: hidden;
        }
      }
    }
  }

  .vulnerability-tabs {
    width: 100%;
    height: 38px;
    display: flex;
    border-bottom: 1px solid #35555a;

    .tooltip {
      width: 500px;
      white-space: break-spaces;
    }

    .tab {
      padding: 10px 20px 15px 19px;
      border-color: #35555a;

      &.overview {
        padding-bottom: 27px;
      }

      &.vulnerabilities {
        padding-bottom: 10px;
      }

      &.assets {
        padding-bottom: 10px;
      }

      &.selected {
        .tab-text {
          color: white;
        }

        .tab-total {
          color: white;
        }
      }
    }

    .tab-text {
      margin: -5px 0 -18px;
      height: 40px;
      color: rgba(255, 255, 255, 0.8);
      font-family: $subtitle;
      font-size: 15px;
      letter-spacing: 1.76px;
      white-space: nowrap;

      .tab-total {
        font-size: 22px;
        font-weight: 200;
        color: rgba(255, 255, 255, 0.5);
        margin-right: 6px;
      }
    }

    .dropdown-container {
      width: 125px;
      position: relative;
      bottom: -6px;
      right: 10px;

      &.open {
        background-color: #14373f;
        border: 1px solid #616B6B;
        padding: 5px 10px 10px;
        border-radius: 2px;
        z-index: 25;
        right: 20px;
        height: 120px;
        bottom: 0;

        .list-item {
          &:hover {
            color: $white;
          }

          &.selected {
            color: $white;
          }
        }
      }
    }
  }

  .table-header-container {
    z-index: 1;
  }

  .first-tab {
    width: 50px;
  }

  .last-tab {
    flex: 1;
    border-right: transparent;
  }

  .vuln-toggle {
    padding-right: 50px;
    align-items: center;

    .tooltip {
      width: 500px;
      white-space: break-spaces;

      .tooltip-icon-small {
        width: 14px;
      }
    }
  }

  .vulnerabilities-filters {
    .tooltip {
      margin-top: 30px;
    }
  }
}
