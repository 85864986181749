@import '../../variables';

.data-list-container-detections {
  position: relative;
  background-image: linear-gradient(#133f47, #002c34);
  margin-top: 0;
  color: #c0d0d0;
  text-align: right;
  flex: 1 1;
  display: flex;
  justify-content: center;
  line-height: 1.8;
  font-weight: 200;
  padding-bottom: 8.4em;
  padding-top: 1.5em;
  padding-right: 0;
  padding-left: 33px;
  &.true {
    background-image: linear-gradient(#133f47, 20%, #002B33);
  }

  .table-title {
    color: white;
    white-space: nowrap;
    font-weight: 450;
    letter-spacing: 1.5px;
    font-family: $heading;
    &.true {
      background-image: linear-gradient(#C0D0D0, transparent);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      height: fit-content;
    }
  }

  .table-container {
    border-spacing: 0;
    margin-top: 1.8em;
    white-space: nowrap;
    position: relative;
    right: 5%;
    .data-table-row {
      line-height: 35px;
      .data-table-cell {
        &.true {
          background-image: linear-gradient(#C0D0D0, transparent);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  .amount {
    font-size: 25px;
    padding-left: 10px;
    font-weight: 100;
    text-align: left;
    &.true {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &.severity {
      color: #E8E8E8;
    }

    .arrow {
      margin: 0 0 5px 5px;
      height: 18px;
    }

    &.yellow {
      color: #FFE133;
      &.true {
        background-image: linear-gradient(#FFE133, transparent);
      }
    }

    &.green {
      color: #D0F31A;
      &.true {
        background-image: linear-gradient(#D0F31A, transparent);
      }
    }
  }

  .critical {
    color: $red;
  }

  .high {
    color: $orange;
  }

  .medium {
    color: #FFE133;
  }

  .low {
    color: #D0F31A;
  }

  .informational {
    color: #066666;
  }
}