@import "../../../variables";

.agents-list {
  clear: left;
  display: flex;
  margin-top: 50px;

  .section-label {
    font-size: 16px;
    color: $grey;
    float: left;
    margin-right: 25px;
  }
  .download-btn {
    cursor: pointer;
    position: relative;
    bottom: 20px;
    float: left;
    margin-right: 45px;

    .icon {
      position: relative;
      &.mac {
        bottom: 4px;
      }
      &.linux {
        bottom: 3px;
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .download-icon {
    position: absolute;
    width: 32px;
    top: 15px;
    margin-left: -12px;
  }
}