@import '../../variables';

.controls-panel {
  position: relative;
  height: 270px;
  padding-top: 25px;
  left: 6vw;
  margin-left: 1vw;

  .controls-svg {
    width: 220px;
    height: 220px;
    cursor: pointer;
  }

  .tooltip {
    &.controls-panel-tooltip {
      left: 90px;
      top: 15px;
    }
  }

  .controls-title {
    border: 1.5px solid #75cbc0;
    border-radius: 20px;
    letter-spacing: 2px;
    background-color: #0f3137;
    font-family: $heading;
    position: absolute;
    width: 115px;
    display: inline-block;
    left: -29px;
    top: 15px;
    padding: 2px 10px;
  }

  .dashboard-controls-graph {
    background-size: 103.5%;
    background-repeat: no-repeat;
    background-position: center center;
    background-position-y: -9px;
    background-position-x: 0;
    width: 220px;
    display: block;
    margin: auto;
    position: relative;
    height: 200px;

    svg {
      cursor: pointer;
    }

    .dashboard-controls-container {
      margin-left: 58px;
      position: relative;
      top: 29px;
    }

    .controls-panel-text {
      left: 90px;
      top: 30px;
      fill: rgb(153, 225, 0);
      color: rgb(153, 225, 0);
      position: relative;
    }

    .total-count {
      position: absolute;
      width: 20px;
      font-family: $paragraph;
      font-size: 16px;
      display: flex;
      justify-content: center;
    }
  }

  .greyed {
    opacity: 0.6;
  }
}
