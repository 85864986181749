.firewall-row {
  .checkbox-cell {
    width: 40px;
  }

  .ip {
    width: 350px;
  }

  .table-icon {
    &.delete {
      width: 28px;
      margin-left: 5px;
    }
  }

  .table-icon-container {
    width: 100px;
  }

  .tooltip {
    width: unset;
  }
}
