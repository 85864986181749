@import "../../variables";

.MFA-container {
  color: $grey;
  font-family: $paragraph;
  width: 550px;
  font-family: helvetica;
  position: relative;
  top: -50px;

  .header {
    font-size: 40px;
    color: $white;
  }
  .mfa-subheader {
    font-weight: 200;
    font-size: 15.5px;
    width: 500px;
    clear: both;
    &.phone {
      padding-top: 10px;
    }
  }

  .description-text {
    width: 250px;
    font-weight: 200;
    font-size: 15.5px;
    float: left;
    margin-right: 50px;
  }

  .confirm-btn-container {
    position: relative;
    margin-top: 60px;
    .confirm-btn {
      background-color: $yellow;
      padding: 10px 20px;
      height: 50px;
      outline: none;
      border: none;
      font-weight: 600;
      font-size: 15.5px;
      cursor: pointer;
      text-transform: uppercase;
      width: 180px;
      &.cancel{
        margin-right: 24px;
      }
      &:hover {
        background-color: #9db816;
      }
      &:focus {
        outline: none;
      }
      .relative-loader {
        margin-top: -10px;
      }
      &.disabled {
        pointer-events: none;
      }
    }
  }

  .success-msg {
    .text {
      position: relative;
      font-size: 20px;
      margin-right: 5px;
      margin-left: 10px;
      bottom: 10px;
    }
  }
  .confirmed-setup-msg {
    position: relative;
    clear: both;
    font-size: 17px;
    top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    .checkmark {
      position: relative;
      top: 3px;
    }
  }
  .error-msg {
    position: absolute;
    bottom: 55px;
    color: $red;
    .err-icon {
      position: relative;
      top: 2px;
      color: $red;
      margin-right: 3px;
    }
  }
  .fine-print {
    position: absolute;
    width: 230px;
    font-size: 14px;
    margin-top: 3px;
  }
  .manual-qr-code {
    .manual-qr-code-reveal {
      position: relative;
      margin-left: 370px;
      background: none;
      border: none;
      font-family: $paragraph;
      font-size: 15.5px;
      font-weight: 500;
      color: #c0d0d0;
      cursor: pointer;
      &:hover {
        color: $yellow;
      }
    }
  }

  .the-code{
    .relative-loader{
      position: relative;
      left: 35px;
      top: -75px;
    }
  }

  .App-2FA-container {
    width: 600px;
    .totp-images {
      padding: 10px;
      margin-left: -50px;
      .label {
        width: 150px;
        font-size: 14px;
        text-align: center;
        margin-top: 3px;
        &.google-authenticator {
          margin-top: 0px;
        }
        &.duo {
          position: relative;
          bottom: 10px;
        }
        &.okta-verify {
          position: relative;
          top: 5px;
        }
      }

      .icon {
        margin-left: 40px;
        &.duo {
          top: 10px;
          position: relative;
        }
        &.okta-verify {
          left: 7px;
          position: relative;
        }
      }

      .google-authenticator {
        padding: 10px;
      }
      .microsoft-authenticator {
        padding: 10px;
      }
      .duo {
        padding: 10px;
      }
    }

    .qr-container{
      margin-top: -5px;
    }

    .verification-code-container {
      margin-top: -120px;
      .input-label {
        font-weight: 600;
        font-family: $heading;
        font-size: 15px;
        margin-bottom: 5px;
        color: $lightgrey;
      }
      padding-right: 45px;
      input {
        width: 227px;
        height: 55px;
        margin-top: 8px;
        border-radius: 5px;
        background-color: #191a1a;
        color: $white;
        border: 1px solid $white;
        padding-left: 18px;
        font-size: 15px;
        &:focus {
          border: 1px solid $yellow;
        }
      }
    }

    .qr-error {
      color: $red;
    }
    .manual-qr-code {
      .key {
        font-size: 14px;
        word-break: break-word;
        margin-bottom: -40px;
        width: 600px;
        &.app-error{
          margin-bottom: -20px;
        }
      }
      .account-name {
        font-size: 14px;
        margin-top: 30px;
      }
    }
  }
}
