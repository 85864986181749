@import "../../variables";

.preferences-container {
  display: block;
  background: linear-gradient(to bottom, #0f3137, 60%, black);
  height: 100%;
  min-height: 100vh;
  position: relative;

  .header-row {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    height: 72px;
  }

  .page-title {
    position: relative;
    left: 25px;
  }

  .side-bar {
    position: absolute;
    background: linear-gradient(to bottom, #163e45, black);
    width: 15%;
    top: 72px;
    right: 0;
    min-height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.2);

    .side-title {
      font-family: $heading;
      font-weight: 600;
      letter-spacing: 1.76px;
      font-size: 16px;
      position: relative;
      top: 25px;
      left: 35px;
    }

    .side-options {
      z-index: 2;
      list-style-type: none;
      position: relative;
      top: 43px;

      .side-opt {
        font-family: $paragraph;
        font-size: 17px;
        cursor: pointer;
        text-transform: capitalize;

        &.selected {
          font-weight: 600;
        }
      }

      :hover {
        color: $yellow;
        font-weight: 600;
      }
    }
  }
}
