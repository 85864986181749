@import '../../variables';

.date-picker-input {
    input {
        background-color: white;
        display: flex;
        text-align: center;
        color: $lightcyan;
        font-size: 14px;
    }
    &.true {
        .MuiInputBase-input {
            border: 1px solid #ff0000 !important;
        }
    }
    &.false {
        .MuiInputBase-input {
            border: 0 !important;
        }
    }
}

.date-picker-validation {
    &.true {
        .MuiInputBase-input {
            border: 1px solid #ff0000 !important;
        }
    }
}

.date-picker-container {
    width: 46%;
    .MuiSvgIcon-root {
        height: fit-content;
    }
    .MuiInputBase-root {
        background-color: transparent !important;
    }
}

.MuiCalendarPicker-root {
    background-color: #04515f !important;
    color: white !important;
    font-family: $paragraph !important;
}

.MuiDialog-paper {
    background-color: #04515f !important;
    color: white !important;
    font-family: $paragraph !important;
}

.MuiSvgIcon-fontSizeMedium {
    color: white;
    font-family: $paragraph !important;
    font-size: 1rem !important;
}

.MuiTypography-root {
    color: white !important;
    font-family: $paragraph !important;
}

.Mui-disabled {
    color: $darkgrey !important;
}

.MuiPickersCalendarHeader-label {
    font-family: $paragraph !important;
}

.MuiButtonBase-root {
    font-family: $paragraph !important;
}