@import '../../variables';

.log-search-container {
  .pagination-controls {
    .sort-by {
      position: relative;
      right: 0;
      width: 90px;
      margin: 0 75px 15px 0;
    }
  }

  .tooltip {
    &.log-source-tooltip {
      left: 110px;
      top: 30px;
    }

    &.search-btn-tooltip {
      top: 28%;
      transform: translateX(0) translateY(-50%);
      left: -190px;
    }

    &.export-logs {
      right: 55px;
    }
  }

  .search-i-icon {
    left: 0;
    margin-top: 45px;
    margin-left: 20px;
    position: absolute;
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    .page-title {
      padding-right: 10px;
      width: 200px;
    }
  }

  .log-search {
    &.true {
      height: unset;
    }
  }

  .log-search-table-row {
    padding-top: 5px;

    .expand-btn {
      cursor: pointer;
      position: absolute;
      left: 2em;
    }

    .log-search-table-cell {
      padding-left: 10px;
      text-align: right;
      vertical-align: baseline;
      color: #7d9395;

      &.value {
        text-align: left;
        display: inline-flex;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
        margin: 0;
        width: 17.5vw;
        color: hsla(0, 0%, 100%, .8);

        &.true {
          white-space: pre-wrap;
        }

        .apply-ellipsis {
          text-overflow: ellipsis;
          overflow-x: hidden;
        }
      }

      &.right {
        padding-bottom: 10px;
      }
    }
  }

  .table-controls {
    margin-right: 20px;

    .reset-filters-button {
      margin: 0 5px 15px 0;
    }

    .export {
      width: 80px;
    }
  }

  .time-dropdown-container {
    padding-right: 200px;
  }

  .inner-log-container {
    width: 100%;
    border-radius: 4px;
    background-color: $darkcyan;
    margin: 15px 0;
    display: flex;
    flex-direction: column;

    .arrow-icon {
      cursor: pointer;
      margin-bottom: -10px;
      color: $grey;

      &.arrow-dark-color {
        padding: 1px;
        margin-top: 1px;
      }
    }

    p {
      margin: 10px 0 0 0;
    }

    .arrow-icon-up {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }

    .time-dropdown {
      display: flex;
      flex: 0;
      flex-grow: 1;
      margin-bottom: 10px;
      padding-right: 6em;
    }

    .log-source-button {
      margin: 10px 10px 0 0;

      &.time {
        justify-content: flex-start;
        text-align: inherit;
        width: 40px;
        margin-top: 10px;
      }
    }

    &.upper {
      flex-direction: row;
      margin-left: 2%;
      justify-content: space-between;
    }

    .time-title {
      padding-bottom: 10px;
      padding-right: 10px;
      margin-top: 4px;
    }

    .log-source-tooltip {
      padding-left: 10px;
      display: inline-block;
    }

    .filter-container {
      width: 55%;
      display: flex;
      margin-left: 4em;
      align-items: center;
      height: calc(100% - 50px);
      padding-top: 17px;
    }

    .log-sources {
      display: flex;

      .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px;
      }

      .log-management-title {
        font-size: 30px;
        font-weight: 500;
        color: $grey;
        margin: -15px 0 40px 5px;
      }
    }

    .time-actions {
      width: 100%;
      flex: 0;
      height: fit-content;
      width: fit-content;

      .search-button {
        background-color: $darkcyan;
        color: #d4d4d4;
        padding: 3px 35px;
        font-size: 16.5px;
        border: 2px solid $grey;
        cursor: pointer;
        margin-top: 52px;
        margin-bottom: 175px;
        font-family: $paragraph;

        &.disabled {
          border: 2px solid #707373;
          color: #707373;
          cursor: unset;
        }
      }
    }

    .past-searches-dropbox {
      padding-right: 76px;
    }

    .quick-time-search {
      width: 97px;
      white-space: nowrap;
    }

    .log-source-title {
      margin-top: 4px;
      padding-top: 7px;
    }

    .dropdown-item {
      display: block;
    }

    .MuiInputBase-root {
      color: $grey;
    }

    .log-source-tooltip {
      .MuiIconButton-root {
        color: $yellow;
        padding: 0;
        padding-bottom: 4px;
      }
    }

    .MuiTextField-root {
      height: 40px;
      width: 13em;
    }

    .ls-row {
      display: flex;
      align-items: center;
    }

    ::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }

    ::-webkit-scrollbar-thumb:horizontal {
      border-radius: 10px;
    }

    .closed-dropbox {
      color: $grey;
      justify-content: space-between;

      &.time {
        width: 240px;
      }
    }

    .closed-log-source {
      margin-top: 0.599em;
      background-color: transparent;
      border: none;
    }

    .closed-log-source-text {
      font-weight: 600;
      font-size: 16px;
      color: $grey;
      cursor: pointer;

      &.month {
        font-weight: 300;
      }
    }

    .closed-sort-by {
      width: 150px;
      margin-top: 2px;
    }

    .log-source-title {
      color: $grey;
      white-space: nowrap;
      width: 100px;
      margin-top: 11px;
    }

    .log-search-fields {
      margin: 30px 15px 30px 0;
      width: 80%;
    }

    .log-search-field {
      display: flex;
      align-items: center;
      margin-top: 5px;
    }

    .search-field-title {
      color: #7D9395;
      font-size: 17px;
      width: 150px;
      text-align: right;
      margin-right: 10px;
      white-space: nowrap;

      &.log-source-title {
        color: rgba(255, 255, 255, 0.6);
        font-family: $paragraph;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.47;
        text-align: left;
        padding-left: 17px;
      }

      &.cloud-services {
        padding-right: 3rem;
        width: 100%;
        position: absolute;
      }

      &.cloud-service-title {
        margin-top: 0.95em;
      }
    }

    .log-search-input {
      justify-content: space-between;
      color: $darkcyan;
      background-color: #FAFBFB;
      border: 1px solid #707070;
      height: 30px;
      width: 100%;
      padding-left: 10px;

      &.ls-dropdown-label {
        background-color: transparent;
        color: white;
        border: none;
        justify-content: unset;
        padding-left: 0;
        margin-top: 8px;
      }
    }

    .closed-transparent-dropbox {
      padding: 0 10px 8px 10px;
      border-radius: 4px;
      cursor: pointer;
      height: 30px;
      width: 240px;
      color: $grey;
      display: flex;
      align-items: center;
    }

    .log-search-dropdown-container {
      width: 100%;
      display: inline-block;
      background-color: transparent;
      justify-content: unset;
    }

    .log-search-dropdown {
      overflow: auto;
      color: $grey;
      position: absolute;
      top: 20.7em;
      width: 27.2em;
      z-index: 99;
      background-color: #002C34;
      max-height: 235px;
    }

    .ls-dropdown {
      width: fit-content;
      display: contents;
    }

    .ls-service-view {
      color: $grey;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.47;
      cursor: pointer;
    }

    .MuiOutlinedInput-input {
      color: $white;
      height: 0;
    }

    .MuiTableRow-hover {
      cursor: pointer;
    }

    .line-filter {
      display: flex;
      justify-content: space-evenly;
      margin-top: 1.6%;
      border-top: 1px solid rgba(255, 255, 255, 0.6);
      &.error {
        margin-bottom: 2.5em;
      }
    }

    .no-results-text {
      margin-left: 3%;
      padding-top: 30px;
    }

    .error-text {
      margin-left: 3%;
      padding-top: 30px;
    }
  }

  .MuiIconButton-root {
    color: $yellow;
    padding-left: 0;
  }

  .MuiPagination-root {
    .MuiButtonBase-root {
      color: $white;
      font-weight: 200;
      max-width: 200px;
      margin: 5px -6px;

      &.Mui-selected {
        background-color: transparent;
        font-weight: bolder;
      }
    }

    .MuiPaginationItem-root {
      color: $white;
      margin: 0;
    }
  }
}

@media (max-width: 1680px) {
  .log-search-table {
    &.pagination-controls {
      .table-controls {
        margin-right: 20px;
        .export {
          width: 80px;
        }
      }
    }
  }
}
