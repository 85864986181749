@import "../variables";

.router-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .nav-container {
    display: flex;
    background-color: rgba(255, 255, 255, 0.1);
    width: 100%;
    height: 50px;
    background-size: 45% 50px;
    background-repeat: no-repeat;
    background-position: right;
    padding-left: 20px;

    a {
      text-decoration: none;
    }

    .nav-left {
      &.parent {
        pointer-events: none;

        .nav-item {
          color: $darkgrey;

          .enabled {
            color: #a6bfbf;
            pointer-events: auto;
          }
        }
      }

      .nav-menu-list {
        display: flex;
        padding-left: 20px;
        align-items: baseline;
        z-index: 10;
        position: relative;

        .nav-logo {
          width: 100px;
          margin: 12px 35px 0 -10px;
        }

        .dropdown-container {
          position: absolute;
          margin: unset;
        }
      }
    }

    .nav-item-container {
      cursor: pointer;
      z-index: 5;
      color: $yellow;

      .nav-item {
        display: flex;
        padding: 15px 25px;
        cursor: pointer;
        color: #a6bfbf;
        white-space: nowrap;
        font-size: 13px;
        letter-spacing: 1px;
        border: 1px solid transparent;
        font-family: $roboto-bold;

        &.disabled-nav {
          cursor: auto;
          color: $darkgrey;

          &:hover {
            border: 1px solid transparent;
          }
        }

        &.sub-item {
          position: relative;
          border: 1px solid rgba(255, 255, 255, 0.1);
          text-align: left;
          color: $white;

          &.disabled-nav {
            cursor: auto;
            color: $darkgrey;

            &:hover {
              border: 1px solid transparent;
              color: $darkgrey;
            }
          }

          &.sub-sub-item {
            color: $grey;
          }

          &:hover {
            border: 1px solid $grey;
          }

          .text {
            &.ZEROIN {
              display: flex;
              align-items: center;

              img {
                margin: -1px 0 0 5px;
              }
            }

            &.FILES {
              position: relative;
              right: 5px;
            }

            &.TICKET {
              height: 50px;
              position: relative;
              border: 1px solid rgba(255, 255, 255, 0.1);
              text-align: left;
              color: $white;
            }
          }
        }

        &.path {
          &:hover {
            color: $yellow;
          }
        }

        &:hover {
          border: 1px solid $darkgrey;
          background-color: rgba(255, 255, 255, 0.1);
        }

        &.no-select {
          cursor: default;
        }

        &.active-dropdown {
          border: 1px solid $darkgrey;
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
    }

    .nav-right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: -5px;
      font-weight: 500;
      font-size: 12px;
      width: 100%;
      align-items: center;
      padding-right: 25px;

      .tenant-dropdown {
        z-index: 100;
      }

      .breach-info {
        height: 55px;
        padding: 10px 40px;

        span {
          color: $grey;
        }

        .breach-number {
          margin-bottom: 3px;
        }

        .breach-email {
          &:hover {
            color: $yellow;
          }
        }
      }

      .user-menu-container {
        position: relative;
        left: 5px;
        padding-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
        min-height: 56px;
        min-width: 30px;
        z-index: 99;
      }

      .user-icon {
        display: flex;
        justify-content: center;
        border: 1px solid $white;
        cursor: pointer;
        padding: 5px;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        color: $white;
        margin-top: 6px;
      }

      .tools-container {
        position: relative;
        min-height: 50px;
        padding-top: 5px;
        margin-left: 2px;
        margin-right: 4px;

        .gear-icon {
          width: 27px;
        }

        .tools-btn {
          z-index: 5;
          display: flex;
          padding: 10px 10px;
          cursor: pointer;
          color: $white;
          white-space: nowrap;
          font-size: 12px;
          letter-spacing: 0.25px;
          border: 1px solid transparent;
          font-family: $paragraph;
          font-weight: 500;

          a {
            color: inherit;
          }

          .text {
            position: relative;
            top: 5px;
            right: 10px;
          }

          &.sub-item {
            height: 50px;
            position: relative;
            border: 1px solid rgba(255, 255, 255, 0.1);
            text-align: left;
            color: $white;

            &.sub-sub-item {
              color: $grey;
            }

            &:hover {
              border: 1px solid $grey;
            }
          }

          &.path {
            &:hover {
              color: $yellow;
            }
          }

          &:hover {
            border: 1px solid $darkgrey;
            background-color: rgba(255, 255, 255, 0.1);
          }

          &.no-select {
            cursor: default;
          }

          &.active-dropdown {
            border: 1px solid $darkgrey;
            background-color: rgba(255, 255, 255, 0.1);
          }

          &.disabled-nav {
            cursor: auto;
            color: $darkgrey;

            &:hover {
              border: 1px solid transparent;
              color: $darkgrey;
            }
          }
        }
      }

      .tools-dropdown {
        position: absolute;
        top: 55px;
        right: 0;
        width: 130px;
      }

      .help-container {
        position: relative;
        min-height: 50px;
        margin-top: 6px;
        margin-left: 5px;
      }

      .help-btn {
        z-index: 5;
        display: flex;
        padding: 15px 10px;
        cursor: pointer;
        color: $white;
        white-space: nowrap;
        font-size: 12px;
        letter-spacing: 0.25px;
        border: 1px solid transparent;
        font-family: $paragraph;
        font-weight: 500;

        a {
          color: inherit;
        }

        &.sub-item {
          position: relative;
          border: 1px solid rgba(255, 255, 255, 0.1);
          text-align: left;
          color: $white;

          &.sub-sub-item {
            color: $grey;
          }

          &:hover {
            border: 1px solid $grey;
          }
        }

        &.path {
          &:hover {
            color: $yellow;
          }
        }

        &:hover {
          border: 1px solid $darkgrey;
          background-color: rgba(255, 255, 255, 0.1);
        }

        &.no-select {
          cursor: default;
        }

        &.active-dropdown {
          border: 1px solid $darkgrey;
          background-color: rgba(255, 255, 255, 0.1);
        }
      }

      .help-dropdown {
        position: absolute;
        top: 50px;
        right: 0;
        width: 160px;
      }

      .user-menu {
        position: absolute;
        top: 55px;
        left: -100px;
        list-style-type: none;
        z-index: 99;

        li {
          margin-bottom: 0;
        }
      }

      .user-menu-btn {
        width: 120px;
        z-index: 5;
        display: flex;
        padding: 15px 10px;
        cursor: pointer;
        color: $white;
        white-space: nowrap;
        font-size: 12px;
        letter-spacing: 0.25px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        font-family: $paragraph;
        font-weight: 500;
        background: linear-gradient(
                        to right,
                        rgba(87, 110, 115, 1) 0%,
                        rgba(46, 82, 89, 1) 30px,
                        rgba(46, 82, 89, 1) 100%
        );
        padding-left: 30px;

        &:hover {
          color: $yellow;
          border: 1px solid $grey;
        }
      }
    }
  }
}
