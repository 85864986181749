@import '../../../variables';

.legend-container {
    .legend-value {
        .svg-rect-container {
            .legend-text-value {
                padding-left: 7px;
                padding-right: 2.5em;
            }
        }
    }
}