@import "../../../variables";

.mobile-onboarding-content {
  position: relative;

  .app-link-container {
    .label {
      float: left;
      margin-right: 30px;
    }
    .ios {
      cursor: pointer;
      position: relative;
      float: left;
      width: 45px;
      bottom: 20px;
      margin-right: 25px;
    }
    .android {
      cursor: pointer;
      position: relative;
      float: left;
      width: 50px;
      bottom: 18px;
    }
  }

  .input-container {
    position: relative;
    clear: both;
    width: 100%;
    padding-top: 20px;
    margin-bottom: 40px;
    .label {
      font-size: 15px;
      margin-bottom: 4px;
    }
    .first_name {
      width: 15%;
    }
    .last_name {
      width: 20%;
    }
    .contact {
      width: 47%;
    }
  }

  input::placeholder {
    color: $red;
  }

  .add-btn-container {
    right: 0px;
    bottom: 0px;
    height: 27px;
    
    .add-btn:hover {
      color: $lightgrey;
      border-color: $lightgrey;
    }
  }

  .actions-container {
    .options {
      width: 80px;
    }
  }
}

.content-container{
  &.mobile{
    &.lower{
      .mobile-onboarding-content {
        .app-link-container{
          margin-top: 55px;
        }
      }
    }
  }
}
