@import "../../../variables";

.virtual-machine-container {
  .copy-container {
    position: relative;
    font-family: $paragraph;
    bottom: 21px;
    left: 12em;
    border: 1px solid $grey;
    padding: 7px 2px 2px 12px;
    background-color: $darkturquoise;
    float: left;
    margin: 0 0 -100px -60px;

    span {
      white-space: nowrap;
    }

    .title {
      font-size: 15px;
      color: $grey;
      margin-bottom: 0;
    }

    .copy-icon {
      &.copied {
        color: $green;
      }
    }
  }
}
