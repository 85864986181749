@import "../../../variables";
.new-scan-date-modal {
  left: 25%;
  top: 40%;
  width: 850px;
  .new-scan-date-modal{
     font-family: $paragraph;
     .response-data-row{
       padding-left: 50px;
     }
    .new-scan-date-modal-title{
      border-bottom: 1px #616B6B solid;
      margin: 0px -20px 10px -65px;
      padding: 0 0 10px 70px;
      font-size: 16px;
    }
    .new-scan-date-modal-description{
      margin: 0px -20px 10px -65px;
      padding: 0 0 10px 70px;
      font-size: 14px;
      color: #a6bfbf;
    }
    .response-modal-note{
      padding: 0 0 0 30px;
      font-size: 14px;
      color: #a6bfbf;
    }
    .new-scan-date-modal-note-italics{
      font-size: 14px;
      color: #a6bfbf;
    }
    .new-scan-date-modal-row-title{
      padding: 0 0 0 30px;
      font-size: 16px;
      color: #a6bfbf;
    }
    .border-btn {
      float: right;
      color: $grey;
      border: #617b80 3px solid;
      text-align: center;
      width: 100px;
      font-size: 16px;
      line-height: 25px;
      margin-left: 5px;
      margin-right: 30px;
      margin-top: 7px;
      cursor: pointer;
      &.cancel {
        float: left;
      }
    }
    .bold-note{
      display:inline;
      font-weight: bolder;
      color: white;
    }
  }

  .modal-data {
    height: unset;
    padding: 12px 20px 20px 12px;
    overflow-y: hidden;
    overflow-x: hidden;

    a {
      text-decoration: none;
      color: $yellow;
      cursor: pointer;

      &:hover {
        color: $white
      }
    }

    .loading-text {
      display: flex;
      align-items: center;
      padding: 3px;

      svg {
        margin-left: 10px;
      }
    }

    .indent {
      margin: -10px 0 0 20px;
    }
    .auto-response{
      color: $yellow;
    }
  }
}
