@import "../../../variables";

.defender-onboarding-container {
  border-top: solid 1px rgba(192, 208, 208, 0.2);
  margin: 30px -77px 0 -74px;
  padding: 50px 80px;
  color: $grey;

  .table-icon {
    &.delete {
      width: 28px;
      margin-left: 5px;
    }
  }

  .title-container {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-bottom: 25px;
    justify-content: space-between;

    .title {
      display: flex;
    }

    .crowdstrike-title {
      margin: 40px 20px 20px 15px;
    }
  }

  .cloud-onboarding-table {
    margin-top: 40px;
  }

  .cloud-modal {
    .page-loading {
      top: 15px;
      right: 75px;
      position: fixed;
    }
  }

  .display-data {
    display: flex;
    justify-content: space-between;
    width: 250px;
    margin-bottom: 30px;
    height: 20px;

    .stat-label {
      float: left;
      color: $grey;
      margin-right: 60px;
    }

    .stat-number {
      color: $lightgrey;
      margin-left: 15px;
    }
  }

  .clickable-link {
    cursor: pointer;
    color: $white;
    text-decoration: none;
    margin: 5px 0 5px 5px;

    &:hover {
      color: $yellow;

      .o365-icon {
        opacity: 0.8;
      }
    }
  }

  .grant-access {
    margin-bottom: -25px;
    height: 70px;
  }

  .border-btn {
    cursor: pointer;
    float: left;
    color: $grey;
    border: #617b80 3px solid;
    text-align: center;
    width: 100px;
    font-size: 16px;
    line-height: 25px;
    margin-right: 35px;

    &:hover {
      color: $white;
      border: $white 3px solid;
    }
  }

  .cloud-table-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;

    .test-error {
      position: initial;
      margin-right: 40px;
    }

    .feedback-icon {
      margin: 10px;
    }
  }

  .account-form-container {
    margin-top: 20px;
  }
  .cs-edit-btn {
    position: relative;
    margin-top: 47px;
    fill: $lightgrey;
    cursor: pointer;
  }
  .cs-edit-btn:hover {
    fill: $white;
  }
  .cs-options {
    margin-top: 15px;
    .cs-options-modal-toggle {
      font-family: $roboto;
      color: $grey;
      cursor: pointer;
      &:hover {
        color: $white;
      }
    }
    .label{
      color: #7b9292;
      margin-right: 5px;
      font-size: 16px;
    }
    .selected-value{
      color: $grey;
      margin-right: 10px;
    }
    .cs-options-icon{
      position: relative;
      top: 2px;
      cursor: pointer;
      fill: $grey;
      &:hover{
        fill: $white;
      }
    }
  }
}
