@import '../../variables';

.key-metrics-container {
  display: flex;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 9;
  height: 270px;

  .key-metrics-parent {
    width: 100%;
    background-color: transparent;

    .relative-loader {
      margin-top: 50px;
    }
  }

  .key-metrics-title {
    border: 1.5px solid #75cbc0;
    white-space: nowrap;
    border-radius: 20px;
    width: fit-content;
    padding: 3px 8px 4px 8px;
    margin: 45px 0 -25px 26%;
    letter-spacing: 2px;
    background-color: #0f3137;
    font-family: $heading;
    position: relative;
  }

  .key-metrics {
    display: flex;
    width: 50vw;
    margin-top: -15px;
    justify-content: center;

    .key-metric {
      background-size: 101.5%;
      background-repeat: no-repeat;
      background-position: center center;
      background-position-y: -25%;
      width: 204px;
      margin-left: -17px;


      .key-metrics-container {
        padding-top: 30.5px;
        padding-left: 34px;
        height: 180px;
      }

      p {
        margin-top: -5px;
      }
    }
  }
}

@media (max-width: 1680px) {
  .key-metrics-container {
    .key-metrics {
      width: 800px;
    }

    .key-metrics-title {
      margin-left: 25%;
    }
  }
}
