@import "../../../variables";

.cloud-onboarding-container {
  .cloud-tabs-container {
    display: flex;
    width: 75%;
    margin-bottom: 50px;
    white-space: nowrap;
  }

  .cloud-content-container {
    float: left;
    width: 100%;
    margin-bottom: 150px;
  }

  .relative-loader {
    position: relative;
    float: left;
    left: 75px;
  }
  .border-btn {
    cursor: pointer;
    float: left;
    color: $grey;
    border: #617b80 3px solid;
    text-align: center;
    width: 100px;
    font-size: 16px;
    line-height: 25px;
    margin-right: 35px;
    margin-top: 7px;

    &.row {
      position: absolute;
      right: 10px;
      margin-bottom: 10px;
    }
  }

  .border-btn:hover {
    color: $white;
    border: $white 3px solid;
  }
  .MuiButtonBase-root {
     padding: 0px;
  }
  .table-icon {
    &.delete {
      width: 12px;
      margin-left: 15px;
    }
  }

  .add-btn {
    color: $grey;
    cursor: pointer;
    margin-top: 0;
  }

  .add-btn:hover {
    color: $white;
    font-weight: 600;
  }

  .form-title {
    position: relative;
    color: $grey;
    margin-bottom: 15px;
    font-size: 15px;
  }

  .error-text {
    color: $red;
    margin-left: 20px;

    .err-icon {
      position: relative;
      top: 2.5px;
      margin-right: 4px;
      color: $red;
    }
  }

  .instructions-tooltip {
    position: absolute;
    right: 0;
    top: 8px;

    .instructions-tooltip {
      width: 130px;
    }

    .tooltip-icon:hover {
      opacity: 0.9;
    }
  }

  .cloud-instructions {
    color: $lightgrey;
    font-size: 15px;
    margin-bottom: 25px;
    width: 100%;

    .instructions-title {
      color: $grey;
    }

    .info-text {
      margin-top: 7px;
      margin-bottom: 7px;
    }

    .info-sub {
      margin-bottom: 7px;

      span {
        color: $grey;
        font-size: 13px;
        margin-left: 4px;
        margin-right: 4px;
        padding: 1px 10px;
        border: #617b80 2px solid;
        user-select: none;
        position: relative;
        bottom: 1px;
      }
    }

    ul {
      margin-top: 7px;
      margin-bottom: 7px;
      padding-left: 15px;
      list-style: none;
    }

    li::before {
      content: "\2022";
      color: $lightgrey;
      font-weight: bold;
      display: inline-block;
      width: 0.75em;
      margin-left: -0.75em;
    }

    li {
      margin-bottom: 3px;
      font-size: 15px;
    }

    .link {
      color: $white;

      &.instructions {
        font-size: 14px;
        margin-left: 148px;
      }

      &:hover {
        color: $yellow;
      }

      text-decoration: none;
    }
  }

  .o365-icon {
    width: 40px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .clickable-link {
    cursor: pointer;
    display: inline-block;
    color: $white;
    text-decoration: none;
    margin: 5px 0 5px 5px;

    &:hover {
      color: $yellow;

      .o365-icon {
        opacity: 0.8;
      }
    }
  }
}
