@import '../../variables';

.no-alerts-container {
  padding-top: 50px;
  color: rgba(255, 255, 255, 0.8);
  font-family: $paragraph;

  .no-alerts-text {
    margin-left: 75px;
  }

  .medium-text {
    font-size: 20px;
    margin-bottom: 0;
  }

  .small-text {
    font-size: 16px;
  }

  .data-container {
    justify-content: space-around;
    padding: 0 5vw 0 3vw;
    background: url("../../../pages/Dashboard/config/images/no-alerts-bg.svg") no-repeat bottom 8px center;
    background-position-x: 16vw;
    width: 50vw;
    min-width: 800px;

    .data-item {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .large-value {
        font-size: 34px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      .value-title {
        font-size: 14px;
      }

      .detections-count {
        color: $orange;
      }

      .investigations-count {
        color: $yellow;
      }
    }
  }
}

