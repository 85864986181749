@import '../../variables';

.maturity-assessment-page-container {
  display: flex;
  flex-direction: column;

  .bold {
    font-weight: bold;
  }

  .header-row {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .total-results{
    margin-left: -10px;
  }
}