.connections-row {
  &.identity {
    padding-bottom: 0;

    .service-icons {
      align-items: center;
      margin-bottom: -10px;
    }

    .circle {
      margin-left: 12px;
    }

    .connection-data {
      margin: 0 0 40px 0;
    }

    .identity-line-chart {
      margin-left: -50px;
    }

    .identity-icon {
      margin-left: -2.5em;
      margin-top: -11px;
    }

    .chart-container {
      .legend-container {
        margin-left: -8px;
      }
    }

    &.clickable{
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}