@import "../../../variables";
.salesforce-connector {
 display: flex;
.radio-selection {
   font-size: 16px;
   margin-left: -25px;
   padding: 20px;
   font-family: $paragraph;
   .radio-selection-text{
     margin-left: -20px;
     font-size: 16px;
   }
   .radio-selection-description{
     margin-top: -10px;
     padding-left: 50px;
     font-size: 14px;
     color: #a6bfbf;
     white-space: pre-line;
   }
}
}
.response-selection{
 &.add{
   margin-left: -5px;
   margin-top: 10px;
   .radio-selection {
   font-size: 16px;
   margin-left: -8px;
   font-family: RobotoCondensedLight, "Roboto Condensed Light";
   .radio-selection-text{
     margin-left: -20px;
     font-size: 16px;
   }
   .radio-selection-description{
     margin-top: 0px;
     padding-left: 31px;
     font-size: 14px;
     color: #a6bfbf;
     white-space: pre-line;
   }
   }
 }
 &.edit{
   margin-left: 5px;
   .radio-selection {
   font-size: 16px;
   margin-left: -8px;
   font-family: RobotoCondensedLight, "Roboto Condensed Light";
   .radio-selection-text{
     margin-left: -20px;
     font-size: 16px;
   }
   .radio-selection-description{
     margin-top: 0px;
     padding-left: 40px;
     font-size: 14px;
     color: #a6bfbf;
     white-space: pre-line;
   }
   }
 }
 }