.connections-row {
  &.firewall {
    padding-bottom: 0;

    .service-icons {
      align-items: center;
      margin-bottom: -10px;

      .new-firewalls-container {
        margin: 30px 0px 0px 20px;

        .new-firewalls {
          text-align: left;

          &.modal-btn {
            text-align: left;
            margin-top: -15px;
            cursor: pointer;
          }
        }
      }
    }

    .connection-data {
      margin: 0 0 40px 0;
    }

    .firewall-line-chart {
      margin-left: -50px;
    }

    .chart-container {
      .legend-container {
        margin-left: -8px;
      }
    }
  }
}
