@import '../../variables';

.button {
  background-color: $darkcyan;
  color: #d4d4d4;
  padding: 3px 35px;
  font-size: 16.5px;
  border: 2px solid $grey;
  cursor: pointer;
  margin-top: 52px;
  margin-bottom: 175px;
  font-family: $paragraph;

  &.disabled {
    border: 2px solid #707373;
    color: #707373;
    cursor: unset;
  }
}
