@import '../../variables.scss';

.reset-filters-button {
  background-color: transparent;
  padding: 5px;
  cursor: pointer;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  font-family: $paragraph;
  font-size: 16px;
  margin: 0 40px 15px 0;
}