@import '../../variables.scss';

.customize-container-radio {
   background-color: $darkturquoise;
   border-radius: 2px;
   border: 1px solid #707070;
   padding: 5px 10px;

   .open-field {
     color: white;
   }

   .closed-field {
     color: #a9abab;
   }

   &.time-and-search {
     span {
       margin: 0 15px;
     }
     padding-top: 20px;
     padding-bottom: 15px;
   }

   &.dropdown-checkbox {

    &.generic-type {
      background-color: transparent;
      color: $grey;
      padding-left: 0;
      padding-top: 0;
      border: none !important;
      min-height: 10em;
      .MuiMenuItem-root {
        min-height: auto;
        height: 20px;
        border-radius: 5px;
        background-color: #002C34;
        padding: 12px 10px 12px 0;
        overflow-x: auto;
        margin-top: 0;
        margin-bottom: 0;
      }
      .MuiCheckbox-root {
        padding-left: 1px;
      }
    }

     &.log-source-type {
       width: 100%;
       background-color: transparent;
       color: $grey;
       padding-left: 1.1rem;
       border: none !important;
       min-height: 10em;
     }
     &.log-source-options {
       position: relative;
       top: 2.6%;
       width: 100%;
       background-color: $darkturquoise;
       color: $grey;
     }

     &.past-search-checkbox {
       height: 130px;
       overflow: auto;
       width: 400px;
     }

     &.sort-by {
       position: absolute;
       right: 20px;
       top: 5px;
       width: 20%;
       max-width: 205px;
     }

     .MuiMenuItem-root {
       background-color: transparent;
       .MuiSvgIcon-root{
          &.enabled{
            color: #D0F31A;
            position: relative;
            top: 2px;
          }
          &.disabled{
            color: #D64426;
            position: relative;
            top: 2px;
          }
          &.unknown{
            color: yellow;
            position: relative;
            top: 2px;
          }
       }
     }

     .open-field {
       background-color: $darkcyan;;
     }

     .right-actions {
       font-size: 14px;
       font-weight: 200;
       color: $grey;

       .MuiSvgIcon-root {
         margin-bottom: -7px;
       }
     }

     .closed-dropbox {
       border: 1px solid #707070;
       padding: 0 10px 8px 10px;
       border-radius: 4px;
       color: $grey;
       cursor: pointer;
       height: 30px;
       background-color: $darkturquoise;

       &.sort-by {
         width: 150px;
         display: flex;
         justify-content: space-between;
       }

       .date-to {
         margin: 0 10px -8px;
         width: 100%;
       }
     }

     .toggle-log-sources {
       display: flex;
       margin-bottom: 72px;
       margin-left: -22px;
       flex-direction: column;

       .closed-dropbox {
         width: 40%;
         justify-content: space-between;
       }
     }
   }

   .MuiMenuItem-root {
     min-height: auto;
     height: 20px;
     border-radius: 5px;
     background-color: $darkcyan;
     padding: 12px 10px 12px 0;
     margin: 5px 0;
     font-weight: 300;
     overflow-x: auto;
     overflow-y: none;
     margin-top: 10px;
     margin-bottom: 10px;

     .MuiListItemText-root {
       margin-left: -20px;
     }

     .MuiListItemIcon-root {
       min-width: 44px !important;
     }

     .MuiTypography-displayBlock {
       display: block;
       font-size: 15px;
       letter-spacing: 0.5px;
       font-family: $paragraph;
     }

     .MuiSvgIcon-root {
       color: $grey;
       width: 1em;
       height: 0.87em;
     }

     .checkbox {
       width: 12px;
       height: 12px;
       margin-left: 5px;

       &.checked {
         background-color: $white;
         border: 1px solid #707070;
       }

       &.unchecked {
         border: 0.5px solid #C0D0D0;
         background-color: #707070;
       }
     }
   }
 }

.customize-container {
  background-color: $darkturquoise;
  border-radius: 2px;
  border: 1px solid #707070;
  padding: 5px 10px;

  &.time-and-search {
    span {
      margin: 0 15px;
    }
    padding-top: 20px;
    padding-bottom: 15px;
  }

  &.dropdown-checkbox {
    &.log-source-type {
      position: relative;
      top: 2.6%;
      width: 100%;
      background-color: transparent;
      color: $grey;
      padding-left: 3rem;
      border: none !important;
    }
    &.log-source-options {
      position: relative;
      top: 2.6%;
      width: 100%;
      background-color: $darkturquoise;
      color: $grey;
    }

    &.past-search-checkbox {
      height: 130px;
      overflow: auto;
      width: 400px;
    }

    &.sort-by {
      position: absolute;
      right: 20px;
      top: 5px;
      width: 20%;
      max-width: 205px;
    }

    .MuiMenuItem-root {
      width: 90%;
      background-color: transparent;
    }

    .open-field {
      background-color: $darkcyan;;
    }
  }

  .MuiMenuItem-root {
    min-height: auto;
    height: 20px;
    border-radius: 5px;
    background-color: $darkcyan;
    padding: 12px 10px 12px 0;
    margin: 5px 0;
    font-weight: 300;
    overflow-x: auto;
    overflow-y: none;
    margin-top: 10px;
    margin-bottom: 10px;

    .MuiListItemText-root {
      margin-left: -20px;
    }

    .MuiTypography-displayBlock {
      display: block;
      font-size: 14px;
      letter-spacing: 0.5px;
    }
  }

  .checked-box-icon {
    width: 15px;
    height: 15px;
    color: white;
    margin-left: 5px;
  }

  .checkbox {
    width: 12px;
    height: 12px;
    margin-left: 5px;

    &.checked {
      background-color: $white;
      border: 1px solid #707070;
    }

    &.unchecked {
      border: 0.5px solid #C0D0D0;
      background-color: #707070;
    }
  }
}
