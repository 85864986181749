@import "../../../variables";

.edit-modal {
    .modal-container {
      width: fit-content;
      position: fixed;
      left: 49%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .edit-modal-content {
      position: relative;
      height: fit-content;
      padding-top: 15px;
      padding-bottom: 70px;
      font-family: $paragraph;
      width: fit-content;
      margin-left: 50px;
    }

    &.O365{
      .edit-modal-content {
        min-height: 205px;
      }
    }

    .modal-data {
      height: fit-content;
      overflow: hidden;
      padding-bottom: 10px;
      &.O365 {
        height: 600px;
      }
    }

    .edit-header {
      position: absolute;
      font-family: $roboto;
      color: $lightgrey;
      padding: 10px;
      padding-left: 15px;
      border-bottom: 1px $darkgrey solid;
      width: 100%;
    }

    .edit-form-container {
      position: relative;
      top: 55px;
    }
  }