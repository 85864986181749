@import "../../variables";

$ip-address: 15%;
$status: 10%;
$label: 12%;
$last-seen: 15%;
$total-events: 13%;
$comment: 35%;

.connections-list-modal {
  .modal-title {
    &.connections-list {
      padding-left: 50px;
    }
  }

  .pagination-controls {
    margin-top: 10px;
  }

  .reset-filters-button {
    display: none;
  }

  .export-bau {
    display: none;
  }
  .tooltip-container{
    .tooltip{
      width: 500px;
      &.bottom{
        top: 15px;
        left: 200px;
      }
    }
  }

  .modal-tooltip {
    position: absolute;
    left: 195px;
    top: 16px;
    cursor: pointer;
  }

  .main-list{
    .circle{
      position: relative;
      margin-left: -20px;
      top: 15px;
    }
    a{
      text-decoration: none;
      color: $grey;
    }
    .sub-list{
      margin-top: 10px;
    }
  }

  .connections-list-table {
    .table-row {
      align-items: center;
      overflow: hidden;
    }

    .list-item {
      width: 25%;
      padding-right: 20px;
      .circle {
        margin-left: 0;
      }
      &.ip-address {
        width: $ip-address;
      }
      &.status {
        width: $status;
      }
      &.label {
        width: $label;
      }
      &.last-seen {
        width: $last-seen;
      }
      &.total-events {
        width: $total-events;
      }
      &.comment {
        width: $comment;
        padding-right: 0px;
      }
      .circle {
        display: flex;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        margin-left: 20px;
        margin-top: 6px;
        background-color: $grey;
  
        &.green {
          background-color: #99E100;
        }
  
        &.red {
          background-color: $red;
        }
  
        &.yellow {
          background-color: $yellow-01;
        }
  
        &.grey {
          background-color: $darkgrey;
        }
      }
    }

    .table-header-container {
      .td-value {
        margin-right: 0px;
        &.ip-address {
          width: $ip-address;
        }
        &.status {
          width: $status;
        }
        &.label {
          width: $label;
        }
        &.last-seen {
          width: $last-seen;
        }
        &.total-events {
          width: $total-events;
        }
        &.comment {
          width: $comment;
        }
      }
    }
  }

  .page-container {
    height: 44px;
  }
}
