@import "../../variables";

body {
  background-color: $darkcyan;
  position: relative;
  height: 100vh;
}

.setup_totp_header {
  font-family: $paragraph;
  font-weight: 200;
  font-size: 18px;
}

.totp-images {
  display: flex;
  font-family: $paragraph;
  font-weight: 300;

  .label {
    width: 100px;
    font-size: 15px;

    &.google-authenticator {
      margin-top: 0;
    }

    &.duo {
      margin-left: 10px;
    }
  }

  .google-authenticator {
    padding: 10px;
  }

  .microsoft-authenticator {
    padding: 10px;
  }

  .duo {
    padding: 10px;
  }
}

.totp-text {
  width: 60%;
  font-family: $paragraph;
  font-weight: 200;
  font-size: 18px;
  padding-bottom: 40px;
}

.setupTOTP {
  .amplify-flex {
    img[alt^="qr code"] {
      position: relative;
      float: right;
      margin-top: -150px;
      width: 30%;
      height: 50%;
    }

    .amplify-flex {
      .amplify-flex {
        display: flex;

        &.amplify-field {
          display: block;
        }

        &.amplify-field-group {
          display: block;
        }

        display: none;
      }

      &.amplify-alert {
        display: flex;

        .amplify-alert__body {
          padding-left: 15px;
        }
      }
    }
  }
}

.reset-password-subheader {
  font-size: 15px;
  padding-bottom: 30px;
  margin-top: -30px;
}

.amplify-button--primary {
  background-color: $yellow;
  padding: 10px 20px;
  height: 50px;
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 15.5px;
  cursor: pointer;
  text-transform: uppercase;
  width: 45%;
  float: right;
  margin-top: 45px;

  &:hover {
    background-color: #9db816;
  }

  &:focus {
    outline: none;
  }
}

.signIn {
  .amplify-button--primary {
    width: 446px;
    margin-top: 70px;
    margin-left: 2px;
    float: left;
  }
}

.amplify-flex {
  border: none;
  padding: 0;
}

.amplify-label {
  font-size: 15.5px;
  font-weight: 600;
}

.amplify-passwordfield {
  margin-top: 20px;
}

.amplify-input {
  width: 100%;
  height: 55px;
  margin-top: 8px;
  border-radius: 5px;
  background-color: #191a1a;
  color: $white;
  border: 1px solid $white;
  padding-left: 18px;
  font-size: 15px;

  &:focus {
    border: 1px solid $yellow;
  }
}

.amplify-field__show-password {
  display: none;
}

.amplify-visually-hidden {
  display: none;
}

.amplify-button--link {
  display: none;
}

.amplify-heading--3 {
  display: none;
}

.amplify-heading--4 {
  display: none;
}

.amplify-alert--error {
  display: none;
}

// following workaround necessary to customize invalid password format error message
.forceNewPassword {
  .amplify-alert--error {
    position: absolute;
    display: initial;
    font-size: 14px;
    color: $red;
    bottom: 355px;

    .amplify-alert__body {
      visibility: hidden;
    }

    .amplify-alert__body::after {
      position: absolute;
      left: 30px;
      bottom: 0;
      width: 100%;
      content: "Password must contain capital letter, number, and symbol. Minimum 8 characters.";
      visibility: visible;
      float: left;
    }

    svg {
      float: left;
    }
  }
}

.amplify-button__loader-wrapper {
  svg {
    display: none;
  }
}

.amplify-text--error {
  position: absolute;
  color: $red;
  margin-top: 2px;
  margin-left: 2px;
  font-size: 14px;
}

.login-container {
  &.hidden {
    display: none;
  }
  font-family: Helvetica;
  margin-top: 10px;
  font-weight: 600;

  *:focus {
    outline: none;
  }

  .logo {
    position: relative;
    top: 15px;
    z-index: 2;
    max-width: 150px;
    margin-bottom: 120px;
    text-align: center;
  }

  .amplify-authenticator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    max-width: 620px;
    max-height: 900px;
  }

  .signin-footer-slot {
    width: 100%;
    display: block;
  }

  .authenticator-container {
    width: 450px;
    z-index: 5;
    position: absolute;
    top: 43.5%;
    transform: translateY(-50%);
    height: 610px;
    left: 9vw;

    .header {
      font-size: 40px;
      margin-bottom: 10px;

      &.forgotPassword {
        margin-top: 200px;
      }

      &.confirmResetPassword {
        margin-top: 50px;
      }

      &.confirmSignIn {
        margin-top: 200px;
      }
    }

    .subheader {
      color: $grey;
      font-size: 15.5px;
      font-weight: 300;
      margin-bottom: 30px;
      margin-left: 2px;
    }
  }

  .reset-container {
    position: relative;
    height: 25px;
    width: 100%;
    top: -110px;
    left: 2px;
    color: $white;
    font-size: 14px;
    color: rgb(130, 130, 130);
    font-weight: 600;
    z-index: 10;

    .link {
      cursor: pointer;
      color: $yellow;

      &:hover {
        color: rgb(208, 243, 27, 0.8);
      }
    }
  }

  .return-btn {
    position: relative;
    width: 115px;
    bottom: 36px;
    cursor: pointer;
    font-size: 14px;
    color: $yellow;

    &:hover {
      color: rgb(208, 243, 27, 0.8);
    }
  }

  .request-account {
    text-align: center;
    margin-top: 25px;
    color: $grey;

    p {
      margin-bottom: 0;

      &:last-child {
        color: $yellow;
      }
    }
  }

  .signin-subtitle {
    color: $grey;
  }

  .bg-actzero {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    overflow: hidden;
    z-index: 0;

    .breach-info {
      float: right;
      font-family: $paragraph;
      font-weight: 300;
      height: 55px;
      padding: 10px 40px;

      span {
        color: $grey;
      }

      .breach-number {
        margin-bottom: 3px;
      }

      .breach-email {
        &:hover {
          color: $yellow;
        }
      }
    }
  }

  .mask-bg {
    position: absolute;
    top: -3000px;
    bottom: -3000px;
    left: -100vw;
    right: 62.5%;
    background-color: $black;
    transform: rotate(30deg);
    z-index: 1;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .error-msg {
    position: relative;
    color: $red;
    font-size: 15.5px;
    margin-top: -20px;
    margin-bottom: 8px;
  }

  .err-icon {
    position: relative;
    top: 2px;
    color: $red;
  }
}

.root-loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
