@import "../../variables";

.onboarding-container {
  display: block;
  background: linear-gradient(to bottom, #0f3137, 60%, black);
  height: 100%;
  min-height: 100vh;
  position: relative;

  &.tenants {
    min-height: 132vh;
  }

  .onboarding-title {
    &.contacts {
      float: left;
      margin-right: 40px;
    }

    margin-top: 40px;
    margin-bottom: 40px;
    font-family: $heading;
    letter-spacing: 2px;
    font-size: 20px;
    color: #d8d8d8;
    .onboarding-title-text {
      &.show {
        padding-right: 100px;
      }
    }
  }

  .upload-btn-container {
    position: relative;
    left: -20px;
    padding: 0;
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    height: 72px;
  }

  .page-title {
    position: relative;
    left: 25px;
  }

  .side-bar {
    position: absolute;
    background: linear-gradient(to bottom, #163e45, black);
    width: 15%;
    top: 72px;
    right: 0;
    min-height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.2);

    .side-title {
      font-family: $heading;
      font-weight: 600;
      letter-spacing: 1.76px;
      font-size: 16px;
      position: relative;
      top: 25px;
      left: 35px;
    }

    .side-options {
      z-index: 2;
      list-style-type: none;
      position: relative;
      top: 43px;

      .side-opt {
        font-family: $paragraph;
        font-size: 17px;
        cursor: pointer;
        text-transform: capitalize;

        &.selected {
          font-family: $roboto-bold;
        }
      }
      .side-opt {
        text-transform: capitalize;
      }
      :hover {
        color: $yellow;
        font-weight: 600;
      }
    }
  }

  .content-container {
    position: relative;
    left: 75px;
    width: 76%;
  }

  .sort-container {
    position: absolute;
    right: 0;
    top: 60px;
    color: #c0d0d0;
    width: 140px;

    .label {
      padding-right: 8px;
    }

    .selection {
      font-weight: bold;
      cursor: pointer;

      .down-arrow {
        position: relative;
        color: #c0d0d0;
        top: 2px;
      }

      .down-arrow:hover {
        color: $white;
      }
    }

    .selection:hover {
      color: $white;

      .down-arrow {
        color: $white;
      }
    }

    .options {
      position: relative;
      left: 50px;
      top: 2px;
      background-color: $darkcyan;
      z-index: 10;
      list-style: none;
      width: 80px;
      border: 1px solid $darkgrey;

      .option {
        cursor: pointer;
        position: relative;
        right: 40px;
        line-height: 30px;
        vertical-align: middle;
        margin: auto;
        width: 80px;
        padding-left: 10px;
      }

      .option:hover {
        color: $white;
      }
    }
  }

  .upload-container {
    position: absolute;
    right: 0;
    top: 7px;

    .text-button {
      float: left;
      margin-right: 100px;
      cursor: pointer;
    }

    .text-button:hover {
      color: $yellow;
    }

    .csv-tooltip {
      width: 200px;
    }
  }

  input {
    margin-right: 10px;
    height: 27px;
    padding: 6px;
    font-family: $paragraph;
    font-size: 15px;
  }

  input:focus {
    outline: none;
  }

  .add-btn-container {
    padding: 0;
    position: absolute;

    .add-btn {
      cursor: pointer;
      color: #c0d0d0;
      border: #617b80 3px solid;
      text-align: center;
      width: 105px;
      height: 30px;
      font-size: 16px;
      line-height: 25px;
      user-select: none;
    }

    .relative-loader {
      position: relative;
      bottom: 12px;
      margin-right: 25px;
    }
  }

  .color {
    color: #c0d0d0;
  }

  .actions-container {
    color: #c0d0d0;
    margin-bottom: -8px;
    padding-top: 35px;

    .selection {
      cursor: pointer;

      .down-arrow {
        position: relative;
        color: #c0d0d0;
        top: 2px;
      }

      .down-arrow:hover {
        color: $white;
      }
    }

    .selection:hover {
      color: $white;

      .down-arrow {
        color: $white;
      }
    }

    .options {
      position: absolute;
      margin-top: 4px;
      background-color: $darkcyan;
      z-index: 10;
      list-style: none;
      border: 1px solid $darkgrey;

      .option {
        cursor: pointer;
        position: relative;
        right: 40px;
        line-height: 30px;
        vertical-align: middle;
        margin: auto;
        width: inherit;
        padding-left: 10px;
        border-bottom: 1px solid $darkgrey;

        &.disabled {
          cursor: default;
          color: $darkgrey;
        }

        &.disabled:hover {
          color: $darkgrey;
        }
      }

      .option:last-child {
        border-bottom: none;
      }

      .option:hover {
        color: $white;
      }
    }
  }

  .error {
    position: absolute;
    font-size: 15px;
    color: $red;
    min-width: 150px;
    font-family: $paragraph;

    .err-icon {
      position: relative;
      top: 2.5px;
      color: $red;
    }

    &.add {
      right: 8px;
      margin-top: 6px;
      text-align: right;
    }

    &.row {
      right: 170px;
      margin-top: 2px;
      min-width: 0;
      width: fit-content;
      padding: 2px;
      padding-right: 5px;
      padding-left: 5px;
      background-color: $lightcyan;
      border: 1px solid $darkgrey;
    }

    &.action {
      left: 80px;
      margin-top: 40px;
    }

    &.edit {
      min-width: 0;
      right: 190px;
      top: 205px;
    }

    &.cloud-modal {
      position: relative;
      right: 75px;
      top: 0;
      float: right;
    }
  }

  .close-icon {
    position: relative;
    color: $red;
    left: 47px;
    top: 3px;
  }

  .upload-btn-container {
    margin-right: 175px;
    padding: 0;
  }

  .files-button {
    cursor: pointer;
    color: $grey;
    border: #617b80 3px solid;
    text-align: center;
    width: 110px;
    height: 30px;
    font-size: 16px;
    line-height: 25px;
    &:hover {
      color: $lightgrey;
      border-color: $lightgrey;
    }
  }

  .pagination-controls {
    position: relative;
    bottom: 85px;
    right: 65px;
  }

  .child-tenants-dropdown {
    position: absolute;
    left: 190px;
    top: 96px;
    z-index: 9;

    &.endpoints {
      left: 230px;
    }
    &.virtual-machine {
      left: 300px;
    }
    &.integrations, &.vulnerability-scan {
      left: 340px;
    }
  }
  .tenant-modal-btn-container {
    position: absolute;
    right: 332px;
    top: 27px;
    z-index: 1;
    cursor: pointer;
    .tenant-info-text {
      padding-right: 12px;
      color: $grey;
    }
    .tenant-modal-btn {
      fill: $grey;
      position: relative;
      top: 2px;
      &:hover{
        fill: $white;
      }
    }
  }
}