@import '../../variables';

.top-vulns-container {
  display: flex;
  white-space: nowrap;
  position: relative;
  top: 3px;

  .top-vulns {
    margin-left: 0px;
    padding-right: 4em;
    padding-left: 2em;
    max-width: 350px;
  }

  .title {
    font-size: 14px;
    margin-bottom: 5px;
  }


  .top-amount {
    text-align: right;
    color: #929292;
    padding-right: 5px;
  }

  .top-item {
    margin-bottom: 0;
    font-weight: 200;
    vertical-align: top;

    td {
      width:0.1%;
      white-space: nowrap;
    }

    .top-name:hover {
      color: $yellow;
    }

    .top-name {
      width: 7em;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      cursor: pointer;
      &.top-vulnerabilities {
        width: 15rem;
      }
    }
  }
}