@import '../../variables';

.vulnerabilities {
  &.modal-container {
    z-index: 15;
    width: 400px;
    left: 60%;
    position: relative;
    top: -67px;
    margin-bottom: -254px;
    box-shadow: none;

    .close-button-container {
      display: none;
    }

    .modal-data {
      height: 255px;
      overflow: hidden;
      padding: 15px;
      width: 500px;

      .page-loading {
        &.relative-loader {
          position: absolute;
          left: 100px;
        }
      }

      .comment-close-icon {
        position: absolute;
        z-index: 999;
        cursor: pointer;
        background-color: #344c50;
        border: 1px solid #515c5d;
        right: -100px;
        top: -21px;
      }

      .modal-title {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: space-between;
        padding: 0 20px 0 15px;
        margin: -5px -15px;
        width: 494px;

        .edit-pencil {
          width: 20px;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }

      .comment-text {
        font-family: $paragraph;
        color: rgba(255, 255, 255, 0.7);
        padding: 15px 0;
        height: 150px;
        overflow-y: auto;
        margin-top: 15px;
        white-space: pre-wrap;
      }

      .comment-time{
        float: right;
        position: relative;
        color: $lightgrey;
        font-family: $paragraph;
        font-size: 14px;
        left: 85px;
        top: 5px;
      }
      
      .time-label{
        padding-right: 5px;
        color: $grey;
      }

      .add-comment-title {
        font-family: $heading;
        padding-bottom: 15px;
        font-weight: bold;
      }

      .add-comment-input {
        border-radius: 1px;
        color: $black;
        font-family: $paragraph;
        margin-top: 15px;

        &::placeholder {
          color: $grey;
        }
      }

      .update-button {
        &.comment {
          margin-top: 15px;
          float: right;
          background-color: transparent;
        }
      }
    }
  }
}
