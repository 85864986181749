@import '../../variables';
@import './InvestigationVariables';

.investigations-page-container {
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: #14373f;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.4);
  }

  .header-row {
    .page-title {
      width: 250px;
    }
  }

  .pagination-controls {
    .sort-by {
      &.open {
        margin: 0 1px -82px 0;
      }
    }
  }

  .investigations-table-container {
    display: flex;
    max-height: 85vh;

    .tooltip {
      min-width: 200px;
      width: fit-content;
      max-width: 250px;
      word-break: break-word;
    }

    .investigations {
      &.table-container {
        .table-header-container {
          .td-value {
            &.Severity {
              min-width: $severity;
              max-width: $severity;
            }

            &.title {
              min-width: $title;
              max-width: $title;
              padding-left: 35px;
            }

            &.affected_resource {
              min-width: $target;
              max-width: $target;
              padding-left: 30px;
            }

            &.Source {
              min-width: $source;
              max-width: $source;
              padding-left: 28px;
            }

            &.created_date {
              min-width: $time;
              max-width: $time;
            }

            &.Status {
              min-width: $status;
              max-width: $status;
            }
          }
        }

        .table-row {
          align-items: center;
          font-weight: 300;
          color: rgba(255, 255, 255, 0.8);
          text-overflow: ellipsis;

          td {
            margin-right: 20px;
          }

          &.expanded {
            background-color: rgba(255, 255, 255, 0.1);
          }

          &:hover {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.1);
          }

          .created_date {
            min-width: $time;
            max-width: $time;
          }

          .source {
            min-width: $source;

            .icon-source-container {
              display: flex;
              align-items: center;

              .icon-container {
                min-width: 100px;
                text-align: center;
                display: inline;
                float: left;
                margin-right: -5px;

                .attack-icon {
                  height: 52px;
                  display: inline-block;
                }
              }

              .text {
                margin-left: -5px;
              }
            }
          }

          .severity {
            min-width: $severity;
            max-width: $severity;
          }

          .title {
            min-width: $title;
            max-width: $title;

            .icon-title-container {
              display: flex;
              align-items: center;

              .tooltip-container {
                text-overflow: ellipsis;
                max-width: 75%;
                display: block;
              }

              .icon-container {
                width: 100px;
                text-align: center;
                display: inline;
                float: left;
                margin-right: -20px;

                .attack-icon {
                  height: 52px;
                  display: inline-block;
                }
              }

              .text {
                text-overflow: ellipsis;
                max-width: 75%;
                display: block;
                overflow: hidden;
                white-space: nowrap;

                &.contained {
                  max-width: 100%;
                }
              }
            }
          }

          .target {
            min-width: $target;
            max-width: $target;

            .icon-target-container {
              display: flex;
              align-items: center;

              .tooltip-container {
                text-overflow: ellipsis;
                max-width: 65%;
                display: block;
              }

              .icon-container {
                min-width: 100px;
                text-align: center;
                display: inline;
                float: left;
                margin-right: -15px;

                .attack-icon {
                  height: 75px;
                  display: inline-block;
                }
              }

              .text {
                text-overflow: ellipsis;
                max-width: 65%;
                display: block;
                overflow: hidden;
                white-space: nowrap;

                &.contained {
                  max-width: 100%;
                }
              }
            }
          }

          .status-bar {
            min-width: $status;
            max-width: $status;
            height: 100%;
          }
        }

        &.expanded {
          min-width: 1065px;
          width: 60vw;
          overflow-y: scroll;
          overflow-x: hidden;

          .table-header-container {
            padding-left: 30px;

            .td-value {
              margin-right: 10px;

              &.Severity {
                min-width: $expanded-severity;
                max-width: $expanded-severity;
                margin-right: -18px;
              }

              &.title {
                min-width: $expanded-title;
                max-width: $expanded-title;
                margin-right: -8px;
              }

              &.affected_resource {
                min-width: 26.5%;
                max-width: 26.5%;
                padding-left: 60px;
              }

              &.Source {
                min-width: $expanded-source;
                max-width: $expanded-source;
                padding-left: 15px;
              }

              &.created_date {
                min-width: $expanded-time;
                max-width: $expanded-time;
              }

              &.Status {
                min-width: $expanded-status;
                max-width: $expanded-status;
              }
            }
          }

          .table-row {
            align-items: center;
            font-weight: 300;
            color: rgba(255, 255, 255, 0.8);
            text-overflow: ellipsis;
            padding-left: 30px;

            td {
              margin-right: 10px;
            }

            &.expanded {
              background-color: rgba(255, 255, 255, 0.1);
            }

            &:hover {
              cursor: pointer;
              background-color: rgba(255, 255, 255, 0.1);
            }

            .created_date {
              min-width: $expanded-time;
              max-width: $expanded-time;
            }

            .source {
              min-width: $expanded-source;

              .icon-source-container {
                display: flex;
                align-items: center;

                .icon-container {
                  margin-right: -10px;
                  min-width: 70px;
                  width: 70px;
                  text-align: center;
                  display: inline;
                  float: left;

                  .attack-icon {
                    height: 52px;
                    display: inline-block;
                  }
                }

                .text {
                  margin-left: 0;
                }
              }
            }

            .severity {
              min-width: $expanded-severity;
              max-width: $expanded-severity;
            }

            .title {
              min-width: $expanded-title;
              max-width: $expanded-title;

              .icon-title-container {
                display: flex;
                align-items: center;

                .tooltip-container {
                  text-overflow: ellipsis;
                  max-width: 75%;
                  display: block;
                }

                .icon-container {
                  max-width: 50px;
                  text-align: center;
                  display: inline;
                  float: left;
                  margin-right: 5px;

                  .attack-icon {
                    height: 52px;
                    display: inline-block;
                  }
                }

                .text {
                  text-overflow: ellipsis;
                  max-width: 75%;
                  display: block;
                  overflow: hidden;
                  white-space: nowrap;

                  &.contained {
                    max-width: 100%;
                  }
                }
              }
            }

            .target {
              min-width: $expanded-target;
              max-width: $expanded-target;

              .icon-target-container {
                display: flex;
                align-items: center;

                .tooltip-container {
                  text-overflow: ellipsis;
                  max-width: 65%;
                  display: block;
                }

                .icon-container {
                  margin-right: -10px;
                  min-width: 70px;
                  max-width: 70px;
                  text-align: center;
                  display: inline;
                  float: left;

                  .attack-icon {
                    height: 65px;
                    display: inline-block;
                  }
                }

                .text {
                  text-overflow: ellipsis;
                  max-width: 65%;
                  display: block;
                  overflow: hidden;
                  white-space: nowrap;

                  &.contained {
                    max-width: 100%;
                  }
                }
              }
            }

            .status-bar {
              min-width: $expanded-status;
              max-width: $expanded-status;
              height: 100%;
            }
          }
        }
      }
    }

    .threat-icon {
      width: 3.5em;
      height: 52px;
    }

    .case_id {
      color: #768181;
    }

    .expand-loader {
      position: absolute;
      right: 16%;
      top: 47%;
    }
  }

  .no-results-text {
    display: flex;
    justify-content: left;
    margin-top: 2%;
    margin-left: 2%;
    font-size: 18px;
    color: #C0D0D0;
  }
}

.threats-msg-container {
  position: absolute;
  left: 420px;
  top: 75px;
}

.threats-msg {
  font-family: $paragraph;
  font-size: 16px;
  color: $orange;
  letter-spacing: 0.1px;
  white-space: nowrap;
}